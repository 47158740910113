import React, { Component } from "react";
import {
  confirmHospital,
  editHospital,
  getNewHospitals,
} from "../../services/hospitalServices";
import NavBarConfigurer from "./nav_bar_configurred";
import NewHospitalTable from "./newHospitalTable";
import CircularProgressIndictor from "../common/circular_progress";

class NewHospital extends Component {
  state = {
    hospitals: [],
    open: false,
    currHos: null,
    status: -1,
  };
  handleOpen = (hos) => {
    this.setState({ open: true, currHos: hos });
  };
  handleClose = () => {
    this.setState({ open: false, currHos: null });
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let hospitals = await getNewHospitals();
    this.setState({ hospitals, status: -1 });
    this.handleClose();
  }

  saveHospital = async (hospital) => {
    let hospitals = [...this.state.hospitals];
    let hI = this.state.hospitals.findIndex((h) => h._id == hospital._id);
    hospitals[hI].name = hospital.name;
    this.setState({ hospitals });
    await editHospital(hospital._id, hospital.name);
    this.handleClose();
  };
  confirm = async (id) => {
    let hospitals = this.state.hospitals.filter((h) => h._id != id);
    this.setState({ hospitals });
    await confirmHospital(id);
  };
  render() {
    return (
      <React.Fragment>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          {this.state.hospitals && (
            <NewHospitalTable
              data={this.state.hospitals}
              confirm={this.confirm}
              open={this.state.open}
              handleOpen={this.handleOpen}
              currHos={this.state.currHos}
              handleClose={this.handleClose}
              onSave={this.saveHospital}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default NewHospital;
