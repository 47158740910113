import React, { Component } from 'react';
import Table from '../common/table';
import { Button } from '@mui/material';
import EditHospital from './editHospital';

class NewHospitalTable extends Component {
    state = { 
        currentPage:1,
        pageSize: 4,
       
     }
    
    columns = [
        {
          path: "name",
          label: "Name",
        },
        { 
            path: "", label: "Doctors",
            content: hospital => <Button color='warning' onClick={()=> this.props.handleOpen(hospital)}>Edit</Button>
        },
        { 
            path: "order_count", label: "Orders",
            content: hospital => <Button onClick={()=> this.props.confirm(hospital._id)}>Confirm</Button>
        },
        
      ]; 
    render() { 
        let {data,onSave,open ,handleClose,currHos} =this.props

        return (<div>
            <Table columns={this.columns} data={data} />
            {open && <EditHospital open={open} handleClose={handleClose} onSave={onSave} hospital={currHos}/>}
            </div>
        );
    }
}
 
export default NewHospitalTable;