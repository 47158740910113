import React, { Component } from 'react';
import Table from '../common/table';
import moment from 'moment/moment';
class PromotionTable extends Component {
    state = {  } 
    columns = [
        {
          path: "title",
          label: "Title",
        },
        { 
            path: "description", label: "Description",
            content: promotion => promotion.description.length>20 ? <React.Fragment>{promotion.description.slice(0,20)}...</React.Fragment> : <React.Fragment>{promotion.description}</React.Fragment>
        },
        { 
            path: "date", label: "Date",
            content: promotion => <React.Fragment>{(moment(promotion.date).fromNow())}</React.Fragment>
        },
        { 
            label: "Delete",
            content: promotion =><button onClick={() =>this.props.onDelete(promotion._id)} className='btn btn-danger'>Delete</button>
        },
        
      ]; 
    render() { 
        return (
            <Table columns={this.columns} data={this.props.data}/>
        );
    }
}
 
export default PromotionTable;