import React from "react";
// import '../App.css';
import "../login.scss";
import Joi from "joi-browser";
import Form from "./common/form";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import auth from "../services/authService";
import CircularProgressIndictor from "./common/circular_progress";
class Login extends Form {
  state = {
    data: { email: "", password: "" },
    errors: {},
    status: -1,
  };
  schema = {
    email: Joi.string().email().min(5).max(255).label("Email").required(),
    password: Joi.string().min(5).max(255).required().label("Password"),
  };
  doSubmit = async () => {
    this.setState({ status: 0 });
    try {
      await auth.login(this.state.data.email, this.state.data.password);
      let detail = auth.getCurrentUser();
      if (detail.role == "doctor") window.location = "/doctor/home";
      else if (detail.role == "pharmacist")
        window.location = "/pharmacist/home";
      else if (detail.role == "driver") window.location = "/driver/home";
      else if (detail.role == "admin" || detail.role == "superadmin")
        window.location = "/admin/home";
    } catch (_) {
      const errors = { ...this.state.errors };
      errors.email = "Incorrect email or password";
      this.setState({ errors });
    }
    this.setState({ status: -1 });
  };
  links = [
    {
      label: (
        <Button variant="contained" color="warning">
          Signin
        </Button>
      ),
      path: "/",
    },
    {
      label: (
        <Button variant="outlined" color="warning">
          Signup
        </Button>
      ),
      path: "/signup",
    },
  ];

  render() {
    return (
      <div>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div
          style={{
            position: "relative",
            opacity: this.state.status == 0 ? 0.7 : 1,
          }}
        >
          <div className="login-root">
            <div className="box-root flex-flex flex-direction--column alla"></div>
            <div className="loginbackground box-background--white padding-top--64">
              <div className="loginbackground-gridContainer">
                <div className="box-root flex-flex first1">
                  <div className="box-root first2"></div>
                </div>
                <div className="box-root flex-flex first3">
                  <div className="box-root box-divider--light-all-2 animationLeftRight tans3s first4"></div>
                </div>
                <div className="box-root flex-flex first5">
                  <div className="box-root box-background--blue800 first6"></div>
                </div>
                <div className="box-root flex-flex first7">
                  <div className="box-root box-background--blue animationLeftRight first8"></div>
                </div>
                <div className="box-root flex-flex first9">
                  <div className="box-root box-background--gray100 animationLeftRight tans3s first10"></div>
                </div>
                <div className="box-root flex-flex first11">
                  <div className="box-root box-background--cyan200 animationRightLeft tans4s first12"></div>
                </div>
                <div className="box-root flex-flex first13">
                  <div className="box-root box-background--blue animationRightLeft first14"></div>
                </div>
                <div className="box-root flex-flex first15">
                  <div className="box-root box-background--gray100 animationRightLeft tans4s first16"></div>
                </div>
                <div className="box-root flex-flex fist17">
                  <div className="box-root box-divider--light-all-2 animationRightLeft tans3s first18"></div>
                </div>
              </div>
            </div>
            <div className="box-root padding-top--24 d-flex flex-column justify-content-center first19">
              <div className="box-root padding-top--48 padding-bottom--24 ">
                <h1
                  style={{ fontFamily: "switzer times-new-roman" }}
                  className="h1"
                >
                  <span style={{ fontSize: 50 }}>International</span>{" "}
                  <span style={{ fontWeight: "300" }}>pharmacy</span>
                </h1>
              </div>
              <div className="formbg-outer">
                <div className="formbg">
                  <div className="formbg-inner padding-horizontal--48">
                    <span className="padding-bottom--15 display-6 span">
                      Login
                    </span>
                    <form id="stripe-login">
                      <div className="field padding-bottom--24">
                        {this.renderInput3("email", "Email", "email")}
                      </div>
                      <div className="field padding-bottom--24">
                        {this.renderInput3("password", "Password", "password")}
                      </div>
                      <div className="field padding-bottom--24">
                        {this.renderButton("Submit")}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="footer-link padding-top--24">
                <span>
                  Don't have an account?{" "}
                  <Link to="/signup" className="a">
                    <Button variant="outlined" className="warning">
                      Sign up
                    </Button>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
