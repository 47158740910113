import http from "./httpService";
import config from "../config.json";

export async function getAllDoctors() {
  let result = await http.get(config.apiUrl + "/doctors");
  return result.data;
}

export async function getHospitalsOfDoctor(id) {
  let result = await http.get(config.apiUrl + "/hospitals/doctors/" + id);
  return result.data;
}

export async function changeActiveness(id) {
  let result = await http.get(config.apiUrl + "/users/" + id + "/activate");
  return result.status;
}
