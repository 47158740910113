import React, { Component } from "react";
import './table.css'
// columns: array
// sortColumn: object
// onSort: function

class TableHeader extends Component {
  render() {
    return (
      <thead style={{backgroundColor: '#EFEFEF'}} className="bg text-dark">
        <tr>
          {this.props.columns.map(column => (
            <th scope="col"
              key={column.path || column.key}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
