import React, { Component } from "react";
import { addToHistory, getPrepared, pickedup } from "../../services/pharmacies";
import Table from "../common/table";
import NavBarConfigurer from "./nav_bar_configurer";
import PrepareDialog from "./popup_dialog";
import Button from "@mui/material/Button";
import moment from "moment";
import CircularProgressIndictor from "../common/circular_progress";
import { Link } from "react-router-dom";
class Prepared extends Component {
  state = {
    data: [],
    open: false,
    order: null,
    status: -1,
  };

  handleOpen = (order) => {
    this.setState({ open: true, order });
  };
  handleClose = async (id, history) => {
    if (typeof id == "string") {
      this.setState({ open: false, order: null });
      await addToHistory(id, history);
    }
    this.setState({ open: false, order: null });
  };
  handlePickUp = async (id) => {
    let data = this.state.data.filter((o) => o._id !== id);
    this.setState({ data });
    this.setState({ open: false });
    let status = await pickedup(id);
  };
  columns = [
    {
      path: "custId",
      label: "Order id",
      content: (o) => (
        <Link
          style={{
            textDecoration: "none",
            fontFamily: "cursive",
            color: "#f44336",
          }}
          to={`/pharmacist/indiv/${o._id}`}
        >
          {o.custId}
        </Link>
      ),
    },
    {
      path: "cust_first_name",
      label: "Pat. Full-N.",
      content: (o) => (
        <React.Fragment>
          {o.cust_first_name} {o.cust_last_name}
        </React.Fragment>
      ),
    },
    {
      path: "type",
      label: "Order type",
    },
    {
      path: "branch",
      label: "Branch",
      content: (order) =>
        order.branch ? (
          <React.Fragment>{order.branch.name}</React.Fragment>
        ) : (
          <React.Fragment>No branch</React.Fragment>
        ),
    },
    {
      path: "",
      label: "Time taken",
      content: (order) => (
        <React.Fragment>
          {moment(Date.now()).diff(order.prescribed_date, "hours")} hours
        </React.Fragment>
      ),
    },
    {
      path: "",
      label: "Actions",
      content: (order) =>
        order.type == "pickup" ? (
          <Button
            variant="outlined"
            onClick={() => this.handleOpen(order)}
            color="warning"
          >
            prepared
          </Button>
        ) : (
          <div>No action</div>
        ),
    },
  ];
  async componentDidMount() {
    this.setState({ status: 0 });
    let data = await getPrepared();
    this.setState({ data, status: -1 });
  }
  render() {
    return (
      <React.Fragment>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          <h2
            className="m-2"
            style={{ fontFamily: "cursive", textAlign: "center" }}
          >
            Currently prepared and undelivered orders
          </h2>
          {this.state.data && (
            <Table columns={this.columns} data={this.state.data} />
          )}
          {this.state.open && (
            <PrepareDialog
              open={this.state.open}
              order={this.state.order}
              onSuccess={this.handlePickUp}
              handleClose={this.handleClose}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Prepared;
