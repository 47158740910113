import React, { Component } from "react";
import { changeActiveness, getAllDoctors } from "../../services/doctorServices";
import DoctorTable from "./doctor_table";
import { paginate } from "../../utils/pageinate";
import Pagination from "../common/pagination";
import SearchBox from "../common/search";
import NavBarConfigurer from "./nav_bar_configurred";
import CircularProgressIndictor from "../common/circular_progress";

class Doctor extends Component {
  state = {
    doctors: [],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    status: -1,
  };
  onActivation = async (userid) => {
    await changeActiveness(userid);
    let doctors = await getAllDoctors();
    this.setState({ doctors });
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let data = await getAllDoctors();
    this.setState({ doctors: data, status: -1 });
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };
  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };
  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      searchQuery,
      doctors: allDoctors,
    } = this.state;

    let filtered = allDoctors;
    if (searchQuery)
      filtered = allDoctors.filter(
        (m) =>
          m.user.first_name
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase()) ||
          m.user.last_name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    const doctors = paginate(filtered, currentPage, pageSize);

    return { totalCount: filtered.length, data: doctors };
  };
  render() {
    const { pageSize, currentPage, searchQuery } = this.state;
    const { totalCount, data: doctors } = this.getPagedData();
    return (
      <div>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          <h2 style={{ fontFamily: "cursive", textAlign: "center" }}>
            Doctors List
          </h2>
          <div className="d-flex justify-content-end m-4">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          <DoctorTable data={doctors} onActivate={this.onActivation} />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default Doctor;
