import moment from "moment/moment";
import React, { Component } from "react";
import { getMyOrders } from "../../services/prescription";
import Table from "../common/table";
import NavBarConfigurer from "./nav_bar_configurer";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/pageinate";
import CircularProgressIndictor from "../common/circular_progress";
import { Link } from "react-router-dom";
class DocOrders extends Component {
  state = {
    data: [],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    status: -1,
    from: moment("2021-04-01T00:00:00.000Z").format("YYYY-MM-DD"),
    to: moment(Date.now()).format("YYYY-MM-DD"),
  };
  columns = [
    {
      path: "cust_first_name",
      label: "Patient F.name",
      content: (o) => (
        <React.Fragment>
          {o.cust_first_name} {o.cust_last_name}
        </React.Fragment>
      ),
    },
    { path: "hospital.name", label: "Hospital" },
    { path: "cust_phone_number", label: "Phone No." },
    {
      path: "",
      label: "Prescribed date",
      content: (order) => (
        <React.Fragment>
          {moment(order.prescribed_date).format("dddd, MMMM Do hh:mm")}
        </React.Fragment>
      ),
    },
    {
      path: "",
      label: "Status",
      content: (order) => <React.Fragment>{order.curr_status}</React.Fragment>,
    },
    {
      path: "",
      label: "View order",
      content: (order) => (
        <Link
          style={{ textDecoration: "none", color: "orangered" }}
          to={`/doctor/orders/${order._id}`}
        >
          View order
        </Link>
      ),
    },
    // {
    //     path: '',
    //     label: "Status",
    //     content: o=><React.Fragment>{ <button onClick={()=> console.log(moment(o.prescribed_date).format('YYYY-MM-DD')< this.state.to)}>'Y'</button>}</React.Fragment>
    // },
  ];
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };
  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };
  getPagedData = () => {
    const { pageSize, currentPage, data: allOrders } = this.state;

    let filtered = allOrders;
    if (this.state.from && this.state.to)
      filtered = allOrders.filter(
        (o) =>
          moment(o.prescribed_date).format("YYYY-MM-DD") >= this.state.from &&
          moment(o.prescribed_date).format("YYYY-MM-DD") <= this.state.to
      );
    const orders = paginate(filtered, currentPage, pageSize);

    return { totalCount: filtered.length, orders };
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let data = await getMyOrders();
    this.setState({ data, status: -1 });
  }
  render() {
    const { pageSize, currentPage } = this.state;
    const { totalCount, orders } = this.getPagedData();
    return (
      <div>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div
          style={{
            position: "relative",
            opacity: this.state.status == 0 ? 0.7 : 1,
          }}
        >
          <NavBarConfigurer />
          <div className="container">
            <h2
              style={{
                textAlign: "center",
                fontWeight: "normal",
                fontSize: "35px",
              }}
            >
              Track my orders
            </h2>
            <div className="d-flex my-2">
              <div>
                From{" "}
                <input
                  type="date"
                  onChange={(e) => this.setState({ from: e.target.value })}
                  value={this.state.from}
                  max={moment(Date.now()).format("YYYY-MM-DD")}
                />
              </div>
              <div className="mx-2"></div>
              <div>
                To{" "}
                <input
                  type="date"
                  onChange={(e) => this.setState({ to: e.target.value })}
                  value={this.state.to}
                  max={moment(Date.now()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <h5>
              You have {totalCount} from{" "}
              <span style={{ fontWeight: "normal" }}>
                {moment(this.state.from).format("dddd, MMMM Do YYYY")}
              </span>{" "}
              to{" "}
              <span style={{ fontWeight: "normal" }}>
                {moment(this.state.to).format("dddd, MMMM Do YYYY")}
              </span>
            </h5>
            <Table columns={this.columns} data={orders} />
            <div className="m-2 d-flex justify-content-end">
              <Pagination
                itemsCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DocOrders;
