import http from "./httpService";
import config from "../config.json";

export async function getAdminPresciption() {
  let result = await http.get(config.apiUrl + "/prescriptions");
  return result.data;
}

export async function getDetailPrescription(id) {
  let result = await http.get(config.apiUrl + `/prescriptions/${id}`);
  return result.data;
}
export async function getHospitalsOrder(hospital_id) {
  let result = await http.get(
    config.apiUrl + `/prescriptions/hospitals/${hospital_id}`
  );
  return result.data;
}
export async function getDoctorsOder(doctor_id) {
  let result = await http.get(
    config.apiUrl + `/prescriptions/doctors/${doctor_id}`
  );
  return result.data;
}
