import React, { Component } from "react";
import NavBar from "../common/navbar";

class NavBarConfigurer extends Component {
  links = [
    { label: "Order", path: "/pharmacist/walking" },
    { label: "Pending", path: "/pharmacist/home" },
    { label: "Preparing", path: "/pharmacist/preparing" },
    { label: "Prepared", path: "/pharmacist/prepared" },
    { label: "Terminated", path: "/pharmacist/terminated" },
    { label: "Pick up", path: "/pharmacist/pickedup" },
    { label: "Delivery", path: "/pharmacist/delivery" },
    {},
  ];
  render() {
    return <NavBar links={this.links} />;
  }
}

export default NavBarConfigurer;
