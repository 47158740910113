import http from "./httpService";
import config from "../config.json";
import _ from "lodash";

export async function getAllUsers() {
  let result = await http.get(config.apiUrl + "/users/active");
  return result.data;
}
export async function getInactiveUsers() {
  let result = await http.get(config.apiUrl + "/users/inactive");
  return result.data;
}

export async function addUser(data) {
  let body = _.pick(data, [
    "first_name",
    "last_name",
    "email",
    "role",
    "password",
    "gender",
  ]);
  body.phoneNumber = data.phone;
  body.isActive = true;
  if (data.role == "pharmacist") body.branch = data.branch;
  let result = await http.post(config.apiUrl + "/users", body);
  return result.status;
}

export async function getUser(id) {
  let result = await http.get(config.apiUrl + "/users/" + id);
  return result.data;
}

export async function editUser(id, data) {
  let body = _.pick(data, [
    "first_name",
    "last_name",
    "email",
    "role",
    "gender",
  ]);
  body.phoneNumber = data.phone;
  let result = await http.put(config.apiUrl + `/users/${id}`, body);
  return result.status;
}

export async function deActivate(id) {
  let result = await http.get(config.apiUrl + `/users/${id}/activate`);
  return result.status;
}

export async function resetPassword(id, password) {
  let result = await http.put(config.apiUrl + `/users/${id}/password`, {
    password,
  });
  return result.status;
}
