import http from "./httpService";
import config from "../config.json";
import _ from "lodash";

export async function prescribeWalking(data, image_link) {
  let body = _.pick(data, [
    "cust_first_name",
    "cust_last_name",
    "cust_phone_number",
    "hospital",
    "type",
    "price",
  ]);
  if (data.type === "pick_up") {
    body.type = "pickup";
  }
  if (data.area) body.area = data.area;
  if (data.sub_area) body.sub_area = data.sub_area;
  if (data.personalizedMedicines) {
    body.personalized_medicines = data.personalizedMedicines;
  }
  if (data.generalizedMedicine) body.medicines = data.generalizedMedicine;
  body.image = image_link;
  let result1 = await http.post(config.apiUrl + "/prescriptions/walking", body);
  return result1;
}
