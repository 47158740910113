import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { deletePromotions, getAllPromotions } from '../../services/promotionServices';
import NavBarConfigurer from './nav_bar_configurred';
import PromotionTable from './promotion_table';
import CircularProgressIndictor from '../common/circular_progress';


class Promotion extends Component {
    state = { 
        data:[]
     } 
    async componentDidMount(){
        this.setState({status:0})
        let data = await getAllPromotions()
        this.setState({data, status:-1})
    }
    deletePromtions= async (id)=>{
        let oldData =[...this.state.data]
        let newDate = oldData.filter(promotion => promotion._id!==id);
        this.setState({data: newDate})
        let result = await deletePromotions(id)
        if (!result || result.status!==204)
        this.setState({data: oldData})
    }
    render() { 
        return (
            <div>
                <div style={{display: this.state.status==0? 'block' : 'none'}}>
                    <CircularProgressIndictor />
                </div>
                <div  style={{opacity: this.state.status==0? 0.7 : 1}}>
                    <NavBarConfigurer />
                    <h1>Promotion</h1>
                    <Link className='btn btn-primary' to='/admin/promotions/new'>Add promotion</Link>
                    <PromotionTable data={this.state.data} onDelete={this.deletePromtions} />
                </div>
                </div>
        );
    }
}
 
export default Promotion;