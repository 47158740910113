import React from "react";
import config from "../../config.json";
import Button from "@mui/material/Button";
import DeleteForever from "@mui/icons-material/DeleteForever";
import "../../App.css";
import Form from "../common/form";
import Joi from "joi-browser";
import { confirm, handleDownload, terminate } from "../../services/pharmacies";
import { Grid } from "@mui/material";
import { Download } from "@mui/icons-material";
// import { getBranches } from '../../services/prescription';
import CircularProgressIndictor from "../common/circular_progress";
import BranchPopup from "./branch_popup";
import TermPopUp from "./termPopup";
class PrescriptionInfo extends Form {
  state = {
    data: {
      price: 0,
      type: "delivery",
      area: "",
      sub_area: "",
    },
    errors: {},
    type: [
      { value: "delivery", label: "Delivery" },
      { value: "pickup", label: "Pick up" },
    ],
    open: false,
    termOpen: false,
  };
  schema = {
    price: Joi.number().required().min(1).max(10000),
    type: Joi.string().required(),
    area: Joi.string().optional().allow(""),
    sub_area: Joi.string().optional().allow(""),
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let data = { ...this.state.data };
    if (this.props.order.type) {
      data.type = this.props.order.type;
    }
    if (this.props.order.area) {
      data.area = this.props.order.area;
    }
    if (this.props.order.sub_area) {
      data.sub_area = this.props.order.sub_area;
    }
    this.setState({ data, status: -1 });
  }
  doSubmit = async () => {
    await confirm(this.props.id, this.state.data);
    this.props.onSuccess();
  };
  terminate = async (message) => {
    await terminate(this.props.id, message);
    this.props.onSuccess();
  };
  areaForm = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-3">{this.renderInput("area", "Area")}</div>
          <div className="col-3">
            {this.renderInput("sub_area", "Sub area")}
          </div>
        </div>
      </React.Fragment>
    );
  };
  branchForm = () => {
    return (
      <React.Fragment>
        {this.renderRadio("branch", "Pick up branch", this.state.branches)}
      </React.Fragment>
    );
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleTermOpen = () => {
    this.setState({ termOpen: true });
  };
  handleTermClose = () => {
    this.setState({ termOpen: false });
  };
  render() {
    let { order } = this.props;
    return (
      <div>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <h1 style={{ textAlign: "center", fontFamily: "cursive" }}>
            Order {order.custId}
          </h1>
          <div className="my-2">
            <div className="d-flex justify-content-center">
              <div>
                <img
                  style={{ maxWidth: "90%" }}
                  src={config.image + order.image}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {" "}
              <div>
                <a href={config.apiUrl + "/image/" + order.image}>Download</a>
              </div>
            </div>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={6}>
              <div className="m-2">
                <h2 style={{ fontFamily: "cursive" }}>Doctor Information</h2>
                <ul>
                  <li>
                    Full-name:{" "}
                    <span
                      style={{ fontFamily: "cursive", textDecoration: "none" }}
                    >
                      {order.doctor.user.first_name}{" "}
                      {order.doctor.user.last_name}
                    </span>{" "}
                  </li>
                  <li>
                    Phone:{" "}
                    <a
                      style={{ fontFamily: "cursive", textDecoration: "none" }}
                      href={`tel:${order["doctor"]["user"]["phoneNumber"]}`}
                    >
                      {" "}
                      {order["doctor"]["user"]["phoneNumber"]}
                    </a>
                  </li>
                  <li>
                    Email:{" "}
                    <a
                      style={{ fontFamily: "cursive", textDecoration: "none" }}
                      href={`mailto:${order["doctor"]["user"]["email"]}`}
                    >
                      {order["doctor"]["user"]["email"]}
                    </a>
                  </li>
                  <li>
                    Hospital:{" "}
                    <span
                      style={{ fontFamily: "cursive", textDecoration: "none" }}
                    >
                      {order.hospital.name}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="m-2">
                <h2 style={{ fontFamily: "cursive", textDecoration: "none" }}>
                  Patient Information
                </h2>
                <ul>
                  <li>
                    Full-name:{" "}
                    <span
                      style={{ fontFamily: "cursive", textDecoration: "none" }}
                    >
                      {order.cust_first_name} {order.cust_last_name}
                    </span>{" "}
                  </li>
                  <li>
                    Phone:{" "}
                    <a
                      style={{ fontFamily: "cursive", textDecoration: "none" }}
                      href={`tel:${order.cust_phone_number}`}
                    >
                      {" "}
                      {order.cust_phone_number}
                    </a>
                  </li>
                  {order.area && <li>Area: {order.area}</li>}
                  {order.sub_area && <li>Subarea: {order.sub_area}</li>}
                </ul>
              </div>
              {order.personalized_medicines &&
                order.personalized_medicines.length > 0 && (
                  <div className="m-2">
                    <h2
                      style={{ fontFamily: "cursive", textDecoration: "none" }}
                    >
                      Compounding Information
                    </h2>
                    <div>
                      <ol>
                        {order.personalized_medicines.map((medicine) => {
                          return (
                            <li>
                              <ol type="A">
                                {medicine.componentMakeUp.map((component) => {
                                  return (
                                    <li>
                                      {component.component.name}{" "}
                                      {component.quantity}{" "}
                                      {component.measurement}
                                    </li>
                                  );
                                })}
                              </ol>
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  </div>
                )}
              {order.medicines && order.medicines.length > 0 && (
                <div className="m-2">
                  <h2 style={{ fontFamily: "cursive", textDecoration: "none" }}>
                    Medicine Information
                  </h2>
                  <div>
                    <ol>
                      {order.medicines.map((medicine) => {
                        return <li>{medicine}</li>;
                      })}
                    </ol>
                  </div>
                </div>
              )}
              {order.history && order.history.length > 0 && (
                <div className="m-2">
                  <h2 style={{ fontFamily: "cursive", textDecoration: "none" }}>
                    History{" "}
                  </h2>
                  <div>
                    <ol>
                      {order.history.map((m) => {
                        return <li>{m}</li>;
                      })}
                    </ol>
                  </div>
                </div>
              )}
              {order.doc_comments && order.doc_comments.length > 0 && (
                <div className="m-2">
                  <h4 style={{ fontFamily: "cursive", textDecoration: "none" }}>
                    Doctor comments{" "}
                  </h4>
                  <div>{order.doc_comments}</div>
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
              <div>
                {this.renderRadio(
                  "type",
                  <div
                    style={{ fontFamily: "cursive", textDecoration: "none" }}
                  >
                    Purchase type
                  </div>,
                  this.state.type
                )}
                {this.state.data.type === "delivery" && this.areaForm()}
                {/* {this.state.data.type==='pickup' && this.branchForm()} */}
                {this.renderInput("price", "Price", "number")}
              </div>
              <Button
                variant="outlined"
                className="mx-3"
                color="warning"
                startIcon={<DeleteForever />}
                type="button"
                onClick={this.handleTermOpen}
              >
                Terminate
              </Button>
              {this.state.termOpen && (
                <TermPopUp
                  handleClose={this.handleTermClose}
                  terminate={this.terminate}
                  onSuccess={this.props.onSuccess}
                  open={this.state.termOpen}
                />
              )}
              <Button
                variant="contained"
                className="mx-3"
                disabled={!order.branch}
                onClick={this.handleOpen}
                type="button"
              >
                Transfer Branch
              </Button>
              {this.state.open && (
                <BranchPopup
                  id={this.props.id}
                  handleClose={this.handleClose}
                  branch={order.branch._id}
                  onSuccess={this.props.onSuccess}
                  open={this.state.open}
                />
              )}
              {this.renderButton("Confirm")}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default PrescriptionInfo;
