import React from 'react';
import Form from '../common/form';
import NavBarConfigurer from './nav_bar_configurred';
import Joi  from 'joi-browser';
import CircularProgressIndictor from '../common/circular_progress';

import { editUser, getUser } from '../../services/usersServices';
class EditUser extends Form {
    state = { 
        data:{first_name:"", last_name:'',email:'', phone:'', gender:'male',role:'pharmacist'},
        errors:{},
        genders: [{value:"male", label:"Male"},{value:'female', label:"Female"}],
        role:[{value:'admin', label:"Manager"},{value:'pharmacist', label:'Pharmacist'},{value:'driver', label:"Driver"}],
        status:-1
    } 
     schema={
        first_name: Joi.string().min(3).max(20).label("First name").required(),
        last_name: Joi.string().min(3).max(20).label("Last name").required(),
        email: Joi.string().email().min(3).max(30).required(),
        phone: Joi.string().min(10).max(10).required().regex(new RegExp('^[0-9]+$')),
        gender:Joi.string().required(),
        role:Joi.string().required()
    }
    async componentDidMount(){
        this.setState({status:0})
        let user = await getUser(this.props.match.params.id)
        let data ={...this.state.data}
        data.first_name =user.first_name
        data.last_name =user.last_name
        data.email =user.email
        data.phone =user.phoneNumber
        data.gender =user.gender
        data.role=user.role
        this.setState({data, status:-1})
    }
    doSubmit= async ()=>{
        this.setState({status:0})
        try{
            const status = await editUser(this.props.match.params.id, this.state.data)
            if (status>=200 && status<400){
                this.props.history.replace('/admin/users')
            }
        }
        catch(_){
            let emailInUse = {...this.state.errors}
            emailInUse.email = "Email already in use"
            this.setState({errors: emailInUse})
        }
        finally{
            this.setState({status:-1})
        }
    }
    render() { 
        return (
            <div>
                <div style={{display: this.state.status==0? 'block' : 'none'}}>
                    <CircularProgressIndictor />
                </div>
                <div style={{opacity: this.state.status==0? 0.7 : 1}}>
                <NavBarConfigurer />
                <h3>Edit user</h3>
                <div className="row">
                    <div className="col-3">{this.renderInput('first_name', 'First name')}</div>
                    <div className="col-3">{this.renderInput('last_name', 'Last name')}</div>
                </div>
                {this.renderInput('email', 'Email', 'email')}
                {this.renderInput('phone','Phone', 'tel')}
                {this.renderRadio('gender', "Gender", this.state.genders)}
                {this.renderRadio('role', "Role", this.state.role)}
                {this.renderButton('Submit')}
                </div>
             </div>
        );
    }
}
 
export default EditUser;