import http from "./httpService";
import config from "../config.json";

export async function getPreparedForDriver() {
  let result = await http.get(
    config.apiUrl + "/prescriptions/preparedForDriver"
  );
  return result.data;
}

export async function recieveForm(id) {
  let result = await http.delete(
    config.apiUrl + `/prescriptions/${id}/preparedForDriver`
  );
  return result.status;
}

export async function getRecievedForDriver() {
  let result = await http.get(config.apiUrl + "/prescriptions/recieved");
  return result.data;
}

export async function deliverForm(id) {
  let result = await http.delete(
    config.apiUrl + `/prescriptions/${id}/deliver`
  );
  return result.status;
}

export async function getDriverHistory() {
  let result = await http.get(config.apiUrl + "/prescriptions/driverHistory");
  return result.data;
}