import React, { Component } from "react";
import NavBarConfigurer from "./nav_bar_configurred";
import Bargraph from "./reusable/bargraph";
import PieGraph from "./reusable/pieGraph";

function range(start, end) {
  var ans = [];
  for (let i = start; i <= end; i++) {
    ans.push(i);
  }
  return ans;
}
class AdminHome extends Component {
  state = {
    val: "2022",
  };
  render() {
    return (
      <div>
        <NavBarConfigurer />
        <h1
          className="m-4"
          style={{ fontFamily: "cursive", textAlign: "center" }}
        >
          International Pharmacy data
        </h1>
        <select
          onChange={(e) => this.setState({ val: e.target.value })}
          defaultValue={+new Date(Date.now()).getFullYear()}
          className="mx-5 my-2"
        >
          {range(2020, 2100).map((opt) => (
            <option>{opt}</option>
          ))}
        </select>
        <Bargraph year={this.state.val} />
        <PieGraph year={this.state.val} />
      </div>
    );
  }
}

export default AdminHome;
