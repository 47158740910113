import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { Component } from 'react';

class ComponentTable extends Component {
    state = {  }
    findComp=(key)=>{
        let {components} =this.props;
        return (components.find(c=> c._id==key)).name
    }
    render() { 
        let {data,components} =this.props
        return (
            <div><Table>
                    <TableHead>
                        <TableCell>Component name</TableCell>
                        <TableCell>Component quantity</TableCell>
                    </TableHead>
                    <TableBody>
                        {Object.entries(data).map(([key, value])=> {
                            return value==0? null: <TableRow>
                                <TableCell>{this.findComp(key)}</TableCell>
                                <TableCell>{value[0]}</TableCell>
                                <TableCell>{value[1]}</TableCell>
                            </TableRow>
                        })
                    }
                    </TableBody>
                </Table>
                
            </div>
        );
    }
}
 
export default ComponentTable;