import moment from "moment";
import React, { Component } from "react";
import { getAdminPresciption } from "../../services/adminPrescription";
import { paginate } from "../../utils/pageinate";
import ListGroup from "../common/listGroup";
import Pagination from "../common/pagination";
import SearchBox from "../common/search";
import NavBarConfigurer from "./nav_bar_configurred";
import OrderTable from "./order_table";
import CircularProgressIndictor from "../common/circular_progress";

class AdminOrder extends Component {
  state = {
    orders: [],
    roles: [
      { _id: "", name: "All orders" },
      { _id: "pending", name: "Pending" },
      { _id: "confirmed", name: "Being prepared" },
      { _id: "prepared", name: "Prepared" },
      { _id: "recieved", name: "Being delivered" },
      { _id: "delivered", name: "Delivered" },
      { _id: "pickedup", name: "Picked up" },
    ],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    selectedRole: null,
    from: moment("2021-04-01T00:00:00.000Z").format("YYYY-MM-DD"),
    to: moment(Date.now()).format("YYYY-MM-DD"),
    status: -1,
  };
  async componentDidMount() {
    let orders = await getAdminPresciption();
    this.setState({ orders });
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };
  handleRoleSelect = (role) => {
    this.setState({ selectedRole: role, currentPage: 1 });
  };
  handleSearch = (query) => {
    this.setState({ searchQuery: query, selectedRole: null, currentPage: 1 });
  };
  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      searchQuery,
      selectedRole,
      orders: allUsers,
    } = this.state;

    let filtered = allUsers;
    if (searchQuery)
      filtered = allUsers.filter(
        (m) =>
          m.cust_first_name
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase()) ||
          m.cust_last_name
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase()) ||
          m.cust_phone_number
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase()) ||
          m.custId.toString().startsWith(searchQuery)
      );
    else if (selectedRole && selectedRole._id)
      filtered = allUsers.filter((m) => m.curr_status === selectedRole._id);
    if (this.state.from && this.state.to)
      filtered = filtered.filter(
        (o) =>
          moment(o.prescribed_date).format("YYYY-MM-DD") >= this.state.from &&
          moment(o.prescribed_date).format("YYYY-MM-DD") <= this.state.to
      );
    const users = paginate(filtered, currentPage, pageSize);

    return { totalCount: filtered.length, data: users };
  };
  render() {
    const { pageSize, currentPage, searchQuery } = this.state;
    const { totalCount, data: users } = this.getPagedData();
    return (
      <div>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          <h1>Order</h1>
          <ListGroup
            items={this.state.roles}
            selectedItem={this.state.selectedRole}
            onItemSelect={this.handleRoleSelect}
          />
          <SearchBox value={searchQuery} onChange={this.handleSearch} />
          <div>
            From{" "}
            <input
              type="date"
              onChange={(e) => this.setState({ from: e.target.value })}
              value={this.state.from}
              max={moment(Date.now()).format("YYYY-MM-DD")}
            />
          </div>
          <div>
            To{" "}
            <input
              type="date"
              onChange={(e) => this.setState({ to: e.target.value })}
              value={this.state.to}
              max={moment(Date.now()).format("YYYY-MM-DD")}
            />
          </div>

          <OrderTable data={users} />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default AdminOrder;
