import http from "./httpService";
import config from "../config.json";
import _ from "lodash";

export async function getMe() {
  let result = await http.get(config.apiUrl + "/doctors" + "/me");
  return result.data;
}
//remember about other in hospitals, tailor personalized medicine and medicine
export async function prescribe(data, image_link) {
  let body = _.pick(data, [
    "cust_first_name",
    "cust_last_name",
    "cust_phone_number",
    "hospital",
  ]);
  if (data.type != "not_decided") body.type = data.type;
  if (data.type === "pick_up") {
    body.type = "pickup";
    body.branch = data.branch;
  }
  if (data.area) body.area = data.area;
  if (data.doc_comments) body.doc_comments = data.doc_comments;
  if (data.sub_area) body.sub_area = data.sub_area;
  if (data.personalizedMedicines) {
    body.personalized_medicines = data.personalizedMedicines;
  }
  if (data.generalizedMedicine) body.medicines = data.generalizedMedicine;
  body.image = image_link;
  let result1 = await http.post(config.apiUrl + "/prescriptions", body);
}

export async function getComponents() {
  let result = await http.get(config.apiUrl + "/components");
  return result.data;
}

export async function getCatComp() {
  let result = await http.get(config.apiUrl + "/categories/components");
  return result.data;
}

export async function addHospitalToMe(hospital_id) {
  let result = await http.put(config.apiUrl + "/doctors/addHospital", {
    hospital: hospital_id,
  });
  return result.data;
}

export async function getMyOrders() {
  let result = await http.get(config.apiUrl + "/prescriptions/myOrders");
  return result.data;
}

export async function getBranches() {
  let result = await http.get(config.apiUrl + "/branches");
  return result.data;
}

export async function searchMedicine(label) {
  let result = await http.get(config.apiUrl + "/medicines/beginning/" + label);
  return result.data;
}
