import React, { Component } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { getCatComp } from "../../services/prescription";
import ComponentTable from "./component_table";
import CircularProgressIndictor from "../common/circular_progress";
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  TextField,
} from "@mui/material";

class PopUp extends Component {
  state = {
    categories: [],
    data: {},
    measurement_data: {},
    components: [],
    status: -1,
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let categories = await getCatComp();
    let measurement_data = {};
    let components = [];
    for (let [_, category] of Object.entries(categories)) {
      for (let component of category.components) {
        measurement_data[component._id] = "gram";
        components.push(component);
      }
    }
    this.setState({ categories, status: -1, measurement_data, components });
  }
  addData = (e) => {
    let data = { ...this.state.data };
    let component_id = e.target.name;
    let quantity = e.target.value;
    let type = e.target.id;
    if ((!quantity || quantity == 0) && data[component_id]) {
      delete data[component_id];
    } else {
      data[component_id] = [
        quantity,
        this.state.measurement_data[component_id],
      ];
    }
    this.setState({ data });
  };
  changeMeasurement = (m, component_id) => {
    let data = { ...this.state.data };
    let measurement_data = { ...this.state.measurement_data };
    // let component_id= e.target.name;
    // let m = e.target.value
    measurement_data[component_id] = m;
    if (data[component_id]) {
      data[component_id] = [data[component_id][0], m];
      this.setState({ data });
    }
    this.setState({ measurement_data });
  };
  save = () => {
    this.props.onSave(this.state.data, this.state.components);
  };
  options = [
    { name: "gram" },
    { name: "%" },
    { name: "capsule" },
    { name: "tablet" },
    { name: "ampule" },
    { name: "sachet" },
    { name: "bottle" },
    { name: "tube" },
    { name: "pre-filled Syringe" },
    { name: "vial" },
  ];
  render() {
    const { open, handleClose } = this.props;
    return (
      <Dialog fullScreen open={open} onClose={handleClose}>
        <div>
          <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
            <CircularProgressIndictor />
          </div>
          <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                Personalize Medicine
              </Toolbar>
            </AppBar>
            <Grid container spacing={2}>
              <Grid xs={8}>
                <List>
                  {Object.values(this.state.categories).map((category) => (
                    <Card style={{ margin: "25px" }}>
                      <details style={{ margin: "20px" }}>
                        <summary>
                          <span>{category.detail.name}</span>
                        </summary>
                        <CardContent id="demo">
                          {category.components.map((component) => (
                            <div>
                              <ListItem>
                                <ListItemText
                                  primary={component.name}
                                  secondary={component.category.name}
                                />
                                {component.isAvailable && (
                                  <input
                                    type="number"
                                    min={0}
                                    id="quantity"
                                    style={{ width: "80px", height: "50px" }}
                                    name={component._id}
                                    onChange={this.addData}
                                  />
                                )}
                                <div className="mx-2"></div>
                                {component.isAvailable && (
                                  <Autocomplete
                                    style={{ width: "130px" }}
                                    id="measurement"
                                    freeSolo
                                    name={component._id}
                                    value={
                                      this.state.measurement_data[component._id]
                                    }
                                    onInputChange={(event, newValue) =>
                                      this.changeMeasurement(
                                        newValue,
                                        component._id
                                      )
                                    }
                                    options={this.options.map(
                                      (option) => option.name
                                    )}
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          label="Mes. type"
                                          {...params}
                                        />
                                      );
                                    }}
                                  />
                                )}
                                {!component.isAvailable && (
                                  <span
                                    style={{
                                      border: "1px red solid",
                                      borderRadius: "5px",
                                      padding: "10px",
                                    }}
                                  >
                                    Not availabe
                                  </span>
                                )}
                              </ListItem>
                              <Divider />
                            </div>
                          ))}
                        </CardContent>
                      </details>
                    </Card>
                  ))}
                </List>
              </Grid>
              <Grid xs={4}>
                <Card
                  style={{
                    margin: "20px",
                  }}
                >
                  <ComponentTable
                    data={this.state.data}
                    components={this.state.components}
                  />
                </Card>
                <Button
                  autoFocus
                  disabled={Object.keys(this.state.data).length === 0}
                  color="warning"
                  onClick={() => this.save()}
                >
                  save
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Dialog>
    );
  }
}
export default PopUp;
// import React, { Component } from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import ListItemText from '@mui/material/ListItemText';
// import ListItem from '@mui/material/ListItem';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import Grid from '@mui/material/Grid';
// import { getComponents } from '../../services/prescription';
// import IndividualPopUp from './individualPopUp';
// import ComponentTable from './component_table';

// class PopUp extends Component {
//     state = {
//         components: [],
//         data:[],
//         newOpen: false,
//         currComponent:null,
//         myset: {}
//      }
//     async componentDidMount(){
//         let components =await getComponents()
//         console.log(components)
//         this.setState({components})
//     }
//     handleNewOpen=(component)=>{
//         this.setState({newOpen: true})
//         this.setState({currComponent: component})
//     }
//     handleNewClose=()=>{
//         this.setState({newOpen: false})
//         this.setState({currComponent:null})
//     }
//     addData=(e)=>{
//         let data =[...this.state.data]
//         let component_id= e.target.name;
//         let component = this.state.components.find(c=> c._id===component_id)
//         let quantity = e.target.value
//         data.push({component, quantity})
//         this.setState({data})
//         this.handleNewClose()
//         let myset = {...this.state.myset}
//         myset[component._id] =true
//         this.setState({myset})
//     }
//     render() {
//     const {open, handleClose,onSave}= this.props
//     return (<Dialog
//         fullScreen
//         open={open}
//         onClose={handleClose}
//       >
//         <AppBar sx={{ position: 'relative' }}>
//           <Toolbar>
//             <IconButton
//               edge="start"
//               color="inherit"
//               onClick={handleClose}
//               aria-label="close"
//             >
//               <CloseIcon />
//             </IconButton>
//               Personalize Medicine

//           </Toolbar>
//         </AppBar>
//         <Grid container spacing={2}>
//             <Grid xs={8}>
//                 <List>
//                     {this.state.components.map((component)=> <div>
//                         <ListItem >
//                             <ListItemText primary={component.name} secondary={component.category.name} />
//                             <input type='number' name={component._id} min={0} onChange={this.addData}/>
//                         </ListItem>
//                         <Divider />
//                     </div>

//                     )}

//                    {this.state.newOpen && <IndividualPopUp component={this.state.currComponent} open={this.state.newOpen} handleClose={this.handleNewClose} onSave={this.addData}/>}

//                 </List>
//             </Grid>
//             <Grid xs={4}>
//                 <ComponentTable data={this.state.data} />
//                 {this.state.data.length>=1 && <Button autoFocus color="inherit" onClick={()=> onSave(this.state.data)}>
//                     save
//                 </Button>}
//             </Grid>
//         </Grid>

//       </Dialog>
//   );
// }
// }
// export default PopUp
