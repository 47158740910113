import { CircularProgress } from '@mui/material';
import React from 'react';


const CircularProgressIndictor = () => {
    return ( 
        <div style={{opacity: 0.7, position:'fixed', top:0, left:0, width:'100vw', height:'100vh', zIndex: 2}}>
        <div style={{height: '100vh'}} className='d-flex justify-content-center align-items-center'>
            <CircularProgress />
        </div>
        </div>
     );
}
 
export default CircularProgressIndictor;