import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getBranches } from '../../services/prescription';
import { transferBranch } from '../../services/pharmacies';

class BranchPopup extends Component {
    state = { 
        branches: []
     } 
    async componentDidMount(){
        let bes= await getBranches()
        let branches =bes.filter(b=> b._id!==this.props.branch)
        this.setState({branches})
    }
    handleTransfer= async (branch)=>{
        await transferBranch(this.props.id, branch)
        // /:id/transfer/:branch
        this.props.handleClose()
        this.props.onSuccess()
        return
    }
    
    render() { 
        let {open, handleClose} = this.props
        return (
            <div>
            <Dialog
              open={open}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>Transfer to</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {
                    this.state.branches.map(b=> <Button onClick={()=>this.handleTransfer(b._id)}>{b.name}</Button>)
                  }
                </DialogContentText>
              </DialogContent>
              {/* <DialogActions>
                <Button onClick={()=>handleClose(order._id,`${moment(Date.now()).format('YYYY-MM-DD hh:mm')}--customer did not answer `)}>Patient not answering</Button>
                <Button onClick={()=>handleClose(order._id,`${moment(Date.now()).format('YYYY-MM-DD hh:mm')}--customer is coming `)}>Patient is coming</Button>
                <Button onClick={()=> onSuccess(order._id)}>Picked up</Button>
              </DialogActions> */}
            </Dialog>
          </div>
        );
    }
}
 
export default BranchPopup;
