import React, { Component } from "react";
import Table from "../common/table";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { getDelivery } from "../../services/pharmacies";
import NavBarConfigurer from "./nav_bar_configurer";
import { paginate } from "../../utils/pageinate";
import Pagination from "../common/pagination";
import SearchBox from "../common/search";
import CircularProgressIndictor from "../common/circular_progress";
class Delivery extends Component {
  state = {
    orders: [],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    status: -1,
    from: moment("2021-04-01T00:00:00.000Z").format("YYYY-MM-DD"),
    to: moment(Date.now()).format("YYYY-MM-DD"),
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let orders = await getDelivery();
    this.setState({ orders, status: -1 });
  }
  columns = [
    {
      path: "custId",
      label: "Order number",
      content: (o) => <Link to={`/pharmacist/indiv/${o._id}`}>{o.custId}</Link>,
    },
    {
      path: "cust_first_name",
      label: "Pat. Full-N.",
      content: (o) => (
        <React.Fragment>
          {o.cust_first_name} {o.cust_last_name}
        </React.Fragment>
      ),
    },
    {
      path: "cust_phone_number",
      label: "Phone number",
    },
    {
      path: "branch",
      label: "Branch",
      content: (order) =>
        order.branch ? (
          <React.Fragment>{order.branch.name}</React.Fragment>
        ) : (
          <React.Fragment>No branch</React.Fragment>
        ),
    },
    {
      path: "prescribed_date",
      label: "Prescribed date and time",
      content: (order) => (
        <React.Fragment>
          {moment(order.prescribed_date).format("dddd, MMMM Do, h:mm")}
        </React.Fragment>
      ),
    },
    {
      path: "doctor.user.first_name",
      label: "Doctor",
      content: (order) => (
        <React.Fragment>
          {order.doctor ? (
            <React.Fragment>{order.doctor.user.first_name}</React.Fragment>
          ) : (
            "WALKING CUSTOMER"
          )}
        </React.Fragment>
      ),
    },
    {
      path: "hospital.name",
      label: "Hospital",
    },
    {
      path: "",
      label: "Time taken",
      content: (order) => (
        <React.Fragment>
          {moment(Date.now()).diff(order.prescribed_date, "hours")} hours{" "}
          {moment(order.delivered_date).diff(order.prescribed_date, "minutes") %
            60}{" "}
          minutes
        </React.Fragment>
      ),
    },
  ];
  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };
  getPagedData = () => {
    const { pageSize, currentPage, searchQuery, orders: allUsers } = this.state;

    let filtered = allUsers;
    if (searchQuery)
      filtered = allUsers.filter(
        (m) =>
          m.cust_first_name
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase()) ||
          m.cust_last_name
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase()) ||
          m.cust_phone_number
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase()) ||
          m.custId.toString().startsWith(searchQuery)
      );
    if (this.state.from && this.state.to)
      filtered = filtered.filter(
        (o) =>
          moment(o.prescribed_date).format("YYYY-MM-DD") >= this.state.from &&
          moment(o.prescribed_date).format("YYYY-MM-DD") <= this.state.to
      );
    const users = paginate(filtered, currentPage, pageSize);

    return { totalCount: filtered.length, orders: users };
  };
  render() {
    const { pageSize, currentPage, searchQuery } = this.state;
    const { totalCount, orders } = this.getPagedData();
    return (
      <div>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          <h2 style={{ fontFamily: "cursive", textAlign: "center" }}>
            Delivered orders
          </h2>
          <div className="d-flex justify-content-end">
            <div style={{ marginRight: "60px" }}>
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
            </div>
          </div>
          <div className="d-flex my-2" style={{ marginLeft: "60px" }}>
            <div>
              From{" "}
              <input
                type="date"
                onChange={(e) => this.setState({ from: e.target.value })}
                value={this.state.from}
                max={moment(Date.now()).format("YYYY-MM-DD")}
              />
            </div>
            <div className="mx-2"></div>
            <div>
              To{" "}
              <input
                type="date"
                onChange={(e) => this.setState({ to: e.target.value })}
                value={this.state.to}
                max={moment(Date.now()).format("YYYY-MM-DD")}
              />
            </div>
          </div>
          <Table columns={this.columns} data={orders} />
          <div className="m-2 d-flex justify-content-end">
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Delivery;
