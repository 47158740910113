import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./components/login";
import SignUp from "./components/sign_up";
import { ToastContainer } from "react-toastify";
import Prescription from "./components/doctor/prescription";
import Hospital from "./components/admin/adminManageHospitals";
import AdminHome from "./components/admin/adminHome";
import Promotion from "./components/admin/adminPromotion";
import AdminOrder from "./components/admin/adminOrders";
import AdminUser from "./components/admin/adminManageUsers";
import HospitalsDocWorksIn from "./components/admin/hospitalsDocWorksIn";
import DoctorsOfHospital from "./components/admin/doctorsOfaHosppital";
import Doctor from "./components/admin/adminManageDoctor";
import DocHome from "./components/doctor/doctorHome";
import DocOrders from "./components/doctor/docOrders";
import AddPromotions from "./components/admin/AddPromotioin";
import AddUsers from "./components/admin/AddUsers";
import EditUser from "./components/admin/editUser";
import InactiveUsers from "./components/admin/inActiveUsers";
import "react-toastify/dist/ReactToastify.css";
import PharmacistHomePage from "./components/pharmacist/home";
import OrderChange from "./components/pharmacist/order_change";
import PreparingForm from "./components/pharmacist/preparing_form";
import Prepared from "./components/pharmacist/prepared";
import DriverHome from "./components/driver/home_page";
import DoctorRoute from "./components/common/doctorRoute";
import AdminRoute from "./components/common/adminRoute";
import PharmacistRoute from "./components/common/pharmacistRoute";
import DriverRoute from "./components/common/driverRoute";
import React, { Component } from "react";
import auth from "./services/authService";
import AdminIndivOrder from "./components/admin/adminIndivOrder";
import HospitalOrder from "./components/admin/hospitalOders";
import NewHospital from "./components/admin/newHospital";
import Categories from "./components/admin/categories";
import Components from "./components/admin/components";
import Branches from "./components/admin/branches";
import PharmIndivOrder from "./components/pharmacist/pharmacistIndivOrder";
import Terminated from "./components/pharmacist/terminated";
import PickedUpOrder from "./components/pharmacist/pickedupOrders";
import Delivery from "./components/pharmacist/delivery";
import DoctorOrder from "./components/admin/doctor_order";
import DocIndivOrder from "./components/doctor/docIndivOrder";
import ContactUs from "./components/doctor/contactUsPage";
import SeeInquiries from "./components/admin/seeInquiries";
import Walking from "./components/pharmacist/walking_customer";
class App extends Component {
  state = {
    user: null,
  };

  componentDidMount() {
    let user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    return (
      <div className="App">
        <ToastContainer />
        <Switch>
          <Route path="/signup" component={SignUp} />
          <DoctorRoute path="/doctor/prescribe" component={Prescription} />
          <AdminRoute path="/admin/inquiries" component={SeeInquiries} />
          <AdminRoute
            path="/admin/doctors/hospitals/:id"
            component={DoctorsOfHospital}
          />
          <AdminRoute
            path="/admin/hospitals/doctors/:id"
            component={HospitalsDocWorksIn}
          />
          <AdminRoute path="/admin/promotions/new" component={AddPromotions} />
          <AdminRoute path="/admin/users/new" component={AddUsers} />
          <AdminRoute path="/admin/users/inactive" component={InactiveUsers} />
          <AdminRoute path="/admin/users/:id" component={EditUser} />
          <AdminRoute path="/admin/promotions" component={Promotion} />
          <AdminRoute
            path="/admin/prescriptions/doctors/:id"
            component={DoctorOrder}
          />
          <AdminRoute
            path="/admin/orders/hospitals/:id"
            component={HospitalOrder}
          />
          <AdminRoute path="/admin/orders/:id" component={AdminIndivOrder} />
          <AdminRoute path="/admin/orders" component={AdminOrder} />
          <AdminRoute path="/admin/hospitals/new" component={NewHospital} />
          <AdminRoute path="/admin/hospitals" component={Hospital} />
          <AdminRoute path="/admin/branches" component={Branches} />
          <AdminRoute path="/admin/doctors" component={Doctor} />
          <AdminRoute path="/admin/Users" component={AdminUser} />
          <AdminRoute path="/admin/components/:id" component={Components} />
          <AdminRoute path="/admin/components" component={Categories} />
          <DoctorRoute path="/doctor/contact" component={ContactUs} />
          <DoctorRoute path="/doctor/home" component={DocHome} />
          <DoctorRoute path="/doctor/orders/:id" component={DocIndivOrder} />
          <DoctorRoute path="/doctor/orders" component={DocOrders} />
          <PharmacistRoute
            path="/pharmacist/home"
            component={PharmacistHomePage}
          />
          <PharmacistRoute
            path="/pharmacist/terminated"
            component={Terminated}
          />
          <PharmacistRoute
            path="/pharmacist/preparing"
            component={PreparingForm}
          />
          <PharmacistRoute
            path="/pharmacist/indiv/:id"
            component={PharmIndivOrder}
          />
          <PharmacistRoute
            path="/pharmacists/pending/:id"
            component={OrderChange}
          />
          <PharmacistRoute path="/pharmacist/prepared" component={Prepared} />
          <PharmacistRoute
            path="/pharmacist/pickedup"
            component={PickedUpOrder}
          />
          <PharmacistRoute path="/pharmacist/delivery" component={Delivery} />
          <PharmacistRoute path="/pharmacist/walking" component={Walking} />
          <DriverRoute path="/driver/home" component={DriverHome} />
          <AdminRoute path="/admin/home" component={AdminHome} />
          <AdminRoute path="/admin" component={AdminHome} />
          {this.state.user && this.state.user.role === "doctor" ? (
            <Redirect to="/doctor/home" />
          ) : null}
          {this.state.user && this.state.user.role === "driver" ? (
            <Redirect to="/driver/home" />
          ) : null}
          {this.state.user && this.state.user.role === "pharmacist" ? (
            <Redirect to="/pharmacist/home" />
          ) : null}
          {this.state.user && this.state.user.role === "admin" ? (
            <Redirect to="/admin/home" />
          ) : null}
          {this.state.user && this.state.user.role === "superadmin" ? (
            <Redirect to="/admin/home" />
          ) : null}
          <Route path="/" component={Login} />
        </Switch>
      </div>
    );
  }
}

export default App;
