import React, { Component } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class EditHospital extends Component {
    state = { 
        hospital: null
     } 
     componentDidMount(){
        let hospital ={...this.props.hospital}
        this.setState({hospital})  
     }
     handleName=(e)=>{
        let hospital ={...this.state.hospital}
        hospital.name = e.target.value
        this.setState({hospital})
     }
    render() { 
        let {open, handleClose,onSave} =this.props
        return (
            <div>
            {this.state.hospital && <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Component</DialogTitle>
              <DialogContent>
                <DialogContentText>
                 Please enter the name of the hospital
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Component name"
                  fullWidth
                  variant="standard"
                  maxRows={3}
                  value= {this.state.hospital.name}
                  onChange={this.handleName}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={()=> { return onSave(this.state.hospital)}}>Save</Button>
              </DialogActions>
            </Dialog>}
          </div>
        );
    }
}
 
export default EditHospital;
