import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Form from '../common/form';
import Joi  from 'joi-browser';

class TermPopUp extends Form {
    state = { 
        data:{message:''},
        errors:{}
     } 
    
    schema={
        message: Joi.string().required().min(3).max(100),
    }
    
    render() { 
        let {open, handleClose,onSuccess, terminate} = this.props
        return (
            <div>
            <Dialog
              open={open}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Pick up Call form"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {this.renderInput('message','Message')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={()=>terminate(this.state.data.message)}>Terminate</Button>
              </DialogActions>
            </Dialog>
          </div>
        );
    }
}
 
export default TermPopUp;
