import { Download } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React, { Component } from "react";
import { getDetailPrescription } from "../../services/adminPrescription";
import { handleDownload } from "../../services/pharmacies";
import config from "../../config.json";
import moment from "moment";
import NavBarConfigurer from "./nav_bar_configurer";
import CircularProgressIndictor from "../common/circular_progress";

class DocIndivOrder extends Component {
  state = {
    order: null,
    status: -1,
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let order = await getDetailPrescription(this.props.match.params.id);
    this.setState({ order, status: -1 });
  }
  render() {
    let { order } = this.state;
    return (
      <div style={{ maxWidth: "100vw" }}>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>

        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          {order && (
            <div>
              <h1 style={{ textAlign: "center" }}>Order</h1>
              <div className="my-2">
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      style={{ maxWidth: "70%" }}
                      alt="not found"
                      src={config.image + order.image}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  {" "}
                  <div>
                    <a href={config.apiUrl + "/image/" + order.image}>
                      Download
                    </a>{" "}
                  </div>
                </div>
              </div>
              <Grid container>
                <Grid className="p-3" item xs={10} md={8} lg={6} xl={3}>
                  {order.personalized_medicines &&
                    order.personalized_medicines.length > 0 && (
                      <div>
                        <h4 className="m-2" style={{ fontFamily: "cursive" }}>
                          Compounding Information
                        </h4>
                        <div>
                          <ol>
                            {order.personalized_medicines.map((medicine) => {
                              return (
                                <li>
                                  <ol type="A">
                                    {medicine.componentMakeUp.map(
                                      (component) => {
                                        return (
                                          <li>
                                            {component.component.name}{" "}
                                            {component.quantity}{" "}
                                            {component.measurement}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ol>
                                </li>
                              );
                            })}
                          </ol>
                        </div>
                      </div>
                    )}
                  {order.medicines && order.medicines.length > 0 && (
                    <div className="m-2">
                      <h4 style={{ fontFamily: "cursive" }}>
                        Medicine Information
                      </h4>
                      <div>
                        <ol>
                          {order.medicines.map((medicine) => {
                            return <li>{medicine}</li>;
                          })}
                        </ol>
                      </div>
                    </div>
                  )}
                  {order.curr_status == "terminated" && (
                    <div className="m-2">
                      <h4 style={{ fontFamily: "cursive" }}>
                        Termination reason
                      </h4>
                      <div>{order.termination_reason}</div>
                    </div>
                  )}
                  <div className="m-2">
                    <h2 style={{ fontFamily: "cursive" }}>
                      Hospital Information
                    </h2>
                    <ul>
                      <li>
                        Name:{" "}
                        <span style={{ fontFamily: "cursive" }}>
                          {order.hospital.name}
                        </span>{" "}
                      </li>
                    </ul>
                  </div>
                </Grid>
                <Grid className="p-3" item xs={10} md={4} lg={6} xl={3}>
                  <span
                    style={{
                      border: "1px solid",
                      fontSize: "35px",
                      padding: "10px",
                    }}
                  >
                    {order.curr_status.toUpperCase()}
                  </span>
                  <div className="m-2">
                    <h2 style={{ fontFamily: "cursive" }}>
                      Patient Information
                    </h2>
                    <ul>
                      <li>
                        Full-name:{" "}
                        <span style={{ fontFamily: "cursive" }}>
                          {order.cust_first_name} {order.cust_last_name}
                        </span>{" "}
                      </li>
                      <li>
                        Phone:{" "}
                        <a
                          style={{
                            textDecoration: "none",
                            fontFamily: "cursive",
                          }}
                          href={`tel:${order.cust_phone_number}`}
                        >
                          {" "}
                          {order.cust_phone_number}
                        </a>
                      </li>
                      {order.area && <li>Area: {order.area}</li>}
                      {order.sub_area && <li>Subarea: {order.sub_area}</li>}
                    </ul>
                  </div>

                  {order.doc_comments && order.doc_comments.length > 0 && (
                    <div className="m-2">
                      <h4 style={{ fontFamily: "cursive" }}>
                        Doctor comments{" "}
                      </h4>
                      <div>{order.doc_comments}</div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DocIndivOrder;
