import { Button, Divider, List, ListItem } from "@mui/material";
import React, { Component } from "react";
import { addCategory, getCategories } from "../../services/components";
import CategoryPopUp from "./categoryPopUp";
import NavBarConfigurer from "./nav_bar_configurred";
import CircularProgressIndictor from "../common/circular_progress";

class Categories extends Component {
  state = {
    categories: null,
    open: false,
    status: -1,
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let categories = await getCategories();
    this.setState({ categories, status: -1 });
  }
  handleSave = async (name) => {
    this.handleClose();
    this.setState({ status: 0 });
    await addCategory(name);
    let categories = await getCategories();
    this.setState({ categories, status: -1 });
  };
  render() {
    return (
      <React.Fragment>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          <h3>Categories</h3>
          <Button onClick={this.handleOpen}>Add category </Button>
          <div className="d-flex justify-content-center">
            <List style={{ width: "500px" }}>
              {this.state.categories &&
                this.state.categories.map((c) => (
                  <React.Fragment>
                    <ListItem
                      style={{ backgroundColor: "#daceee", cursor: "pointer" }}
                      onClick={() =>
                        this.props.history.push(`/admin/components/${c._id}`)
                      }
                      className="d-flex justify-content-center"
                    >
                      {c.name}
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              {this.state.open && (
                <CategoryPopUp
                  open={this.state.open}
                  handleClose={this.handleClose}
                  onSave={this.handleSave}
                />
              )}
            </List>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Categories;
