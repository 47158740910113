import React, { Component } from 'react';
import NavBar from '../common/navbar';
class NavBarConfigurer extends Component {
    links= [
      {label: 'Home', path:'/doctor/home'},
      {label: 'Orders', path: '/doctor/orders'},
      {}
    ]
  render() { 
    return (
      <NavBar links={this.links} />
    );
  }
}
 
export default NavBarConfigurer;