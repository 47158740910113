import React, { Component } from 'react';
import NavBar from '../common/navbar';

class NavBarConfigurer extends Component {
    links=[
        {label: 'Home', path:'/driver/home'},
        {}
    ]
    render() { 
        return (
            <NavBar links={this.links} />
        );
    }
}
 
export default NavBarConfigurer;