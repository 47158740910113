import { Download } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React, { Component } from "react";
import { getDetailPrescription } from "../../services/adminPrescription";
import { handleDownload } from "../../services/pharmacies";
import NavBarConfigurer from "./nav_bar_configurred";
import config from "../../config.json";
import moment from "moment";
import CircularProgressIndictor from "../common/circular_progress";
import { Link } from "react-router-dom";
class AdminIndivOrder extends Component {
  state = {
    order: null,
    status: -1,
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let order = await getDetailPrescription(this.props.match.params.id);
    this.setState({ order, status: -1 });
  }
  render() {
    let { order } = this.state;
    return (
      <div style={{ maxWidth: "100vw" }}>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          {order && (
            <div>
              <h1 style={{ textAlign: "center" }}>Order {order.custId}</h1>
              <div className="my-2">
                <div className="d-flex justify-content-center">
                  <div>
                    <img
                      style={{ maxWidth: "90%" }}
                      alt="not found"
                      src={config.image + order.image}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  {" "}
                  <div>
                    <a href={config.apiUrl + "/image/" + order.image}>
                      Download
                    </a>
                  </div>
                </div>
              </div>
              <Grid container>
                <Grid className="p-3" item xs={10} md={8} lg={6} xl={3}>
                  {order.doctor ? (
                    <div className="m-2">
                      <h2 style={{ color: "orangered" }}>Doctor Information</h2>
                      <ul>
                        <li>
                          Full-name:{" "}
                          <span style={{ fontSize: 22 }}>
                            {order.doctor.user.first_name}{" "}
                            {order.doctor.user.last_name}
                          </span>{" "}
                        </li>
                        <li>
                          Hospital:{" "}
                          <span style={{}}>{order.hospital.name}</span>{" "}
                        </li>
                        <li>
                          Phone:{" "}
                          <a
                            style={{
                              textDecoration: "none",
                              color: "orangered",
                            }}
                            href={`tel:${order["doctor"]["user"]["phoneNumber"]}`}
                          >
                            {" "}
                            {order["doctor"]["user"]["phoneNumber"]}
                          </a>
                        </li>
                        <li>
                          Email:{" "}
                          <a
                            style={{
                              textDecoration: "none",
                              color: "orangered",
                            }}
                            href={`mailto:${order["doctor"]["user"]["email"]}`}
                          >
                            {order["doctor"]["user"]["email"]}
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <h4>Walk-in customer</h4>
                  )}

                  {order.personalized_medicines &&
                    order.personalized_medicines.length > 0 && (
                      <div>
                        <h4 className="m-2" style={{ color: "orangered" }}>
                          Compounding information
                        </h4>
                        <div>
                          <ol>
                            {order.personalized_medicines.map((medicine) => {
                              return (
                                <li>
                                  <ol type="A">
                                    {medicine.componentMakeUp.map(
                                      (component) => {
                                        return (
                                          <li>
                                            {component.component.name}{" "}
                                            {component.quantity}{" "}
                                            {component.measurement}
                                          </li>
                                        );
                                      }
                                    )}
                                  </ol>
                                </li>
                              );
                            })}
                          </ol>
                        </div>
                      </div>
                    )}
                  {order.confirmed_date && (
                    <div className="m-2">
                      <h4 style={{ color: "orangered" }}>
                        Confirmation Information
                      </h4>
                      <div>
                        <ul>
                          <li>
                            Date:{" "}
                            <span
                              style={{
                                textDecoration: "none",
                                color: "orangered",
                              }}
                            >
                              {moment(order.confirmed_date).format(
                                "YYYY-MM-DD hh:mm"
                              )}
                            </span>
                          </li>
                          <li>
                            Price:{" "}
                            <span
                              style={{
                                textDecoration: "none",
                                color: "orangered",
                              }}
                            >
                              {order.price} birr
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  {order.curr_status == "terminated" && (
                    <div className="m-2">
                      <h4 style={{ color: "orangered" }}>Termination reason</h4>
                      <div>{order.termination_reason}</div>
                    </div>
                  )}
                </Grid>
                <Grid className="p-3" item xs={10} md={4} lg={6} xl={3}>
                  <span
                    style={{
                      border: "1px solid",
                      fontSize: "35px",
                      padding: "10px",
                    }}
                  >
                    {order.curr_status.toUpperCase()}
                  </span>
                  <div className="m-2">
                    <h2 style={{ color: "orangered" }}>Patient Information</h2>
                    <ul>
                      <li>
                        Full-name:{" "}
                        <span style={{ fontSize: 22 }}>
                          {order.cust_first_name} {order.cust_last_name}
                        </span>{" "}
                      </li>
                      <li>
                        Phone:{" "}
                        <a
                          style={{ textDecoration: "none", color: "orangered" }}
                          href={`tel:${order.cust_phone_number}`}
                        >
                          {" "}
                          {order.cust_phone_number}
                        </a>
                      </li>
                      {order.area && <li>Area: {order.area}</li>}
                      {order.sub_area && <li>Subarea: {order.sub_area}</li>}
                    </ul>
                  </div>
                  {order.medicines && order.medicines.length > 0 && (
                    <div className="m-2">
                      <h4 style={{ color: "orangered" }}>
                        Medicine information
                      </h4>
                      <div>
                        <ol>
                          {order.medicines.map((medicine) => {
                            return <li>{medicine}</li>;
                          })}
                        </ol>
                      </div>
                    </div>
                  )}
                  {order.prepared_date && (
                    <div className="m-2">
                      <h4 style={{ color: "orangered" }}>Actions taken</h4>
                      <div>
                        <ol>
                          <li>
                            {order.confirming_pharmacist.first_name}{" "}
                            {order.confirming_pharmacist.last_name} prepared the
                            medicines on{" "}
                            {moment(order.prepared_date).format("hh:mm")}
                          </li>
                          {order.history.map((h) => (
                            <li>{h}</li>
                          ))}
                          {order.recieved_date && (
                            <li>
                              {order.deliverer.first_name} recieved the order on{" "}
                              {moment(order.recieved_date).format(
                                "YYYY-MM-DD hh:mm"
                              )}
                            </li>
                          )}
                          {order.recieved_date && order.delivered_date && (
                            <li>
                              {order.deliverer.first_name} delivered the order
                              on{" "}
                              {moment(order.delivered_date).format(
                                "YYYY-MM-DD hh:mm"
                              )}
                            </li>
                          )}
                          {order.curr_status == "pickedup" &&
                            order.delivered_date && (
                              <li>
                                Customer picked up the order on{" "}
                                {moment(order.delivered_date).format(
                                  "YYYY-MM-DD hh:mm"
                                )}
                              </li>
                            )}
                          {order.delivered_date && (
                            <li>
                              Total time taken:{" "}
                              {moment(order.delivered_date).diff(
                                order.prescribed_date,
                                "hours"
                              )}{" "}
                              hours{" "}
                              {moment(order.delivered_date).diff(
                                order.prescribed_date,
                                "minutes"
                              ) % 60}{" "}
                              minutes
                            </li>
                          )}
                        </ol>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AdminIndivOrder;
