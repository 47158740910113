import React, { Component } from "react";
import { getDoctorsOfHospital } from "../../services/hospitalServices";
import DoctorTable from "./doctor_table";
import NavBarConfigurer from "./nav_bar_configurred";
import CircularProgressIndictor from "../common/circular_progress";

class DoctorsOfHospital extends Component {
  state = {
    data: [],
    status: -1,
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let data = await getDoctorsOfHospital(this.props.match.params.id);
    this.setState({ data, status: -1 });
  }
  render() {
    return (
      <div>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          <DoctorTable data={this.state.data} />
        </div>
      </div>
    );
  }
}

export default DoctorsOfHospital;
