import { Button} from '@mui/material';
import React, { Component } from 'react';
import { addComponent, changeStatus,  getComponents } from '../../services/components';
import Table from '../common/table';
import ComponentPopup from './componentPopUp';
import NavBarConfigurer from './nav_bar_configurred';
import CircularProgressIndictor from '../common/circular_progress';

class Components extends Component {
    state = { 
        compoents: [],
        open: false,
        status:-1
     }
     handleOpen=()=>{
        this.setState({open: true})
      }
      handleClose=()=>{
        this.setState({open: false})
      }
    async componentDidMount(){
        this.setState({status:0})
       let compoents=await getComponents(this.props.match.params.id)
       this.setState({compoents,status:-1})
    }
    async changeStock(id){
        let first_c =[...this.state.compoents]
        let c= [...this.state.compoents]
        let index= c.findIndex(comp=> comp._id===id)
        let new_c= {...c[index]}
        new_c.isAvailable =!new_c.isAvailable
        c[index] = new_c
        this.setState({compoents: c})
        let status = await changeStatus(id)
        if(status!=201)
        this.setState({compoents: first_c})

    }
     handleSave= async(name)=>{
        this.handleClose()
        this.setState({status:0})
        await addComponent(name, this.props.match.params.id)
        let compoents=await getComponents(this.props.match.params.id)
        this.setState({compoents, status:-1})
    }
    columns=[
        {
            path: 'name',
            label: 'Component name'
        },
        {
            path :'',
            label :'',
            content: c=>  <Button color='warning' disabled={!c.isAvailable} onClick={()=> this.changeStock(c._id)}>Mark out of stock</Button>
        },
        {
            path :'',
            label :'',
            content: c=>  <Button color='success' disabled={c.isAvailable} onClick={()=> this.changeStock(c._id)}>Back to stock</Button>
        }
    ]
    render() { 
        return (
            <React.Fragment>
                <div style={{display: this.state.status==0? 'block' : 'none'}}>
                    <CircularProgressIndictor />
                </div>
                <div style={{opacity: this.state.status==0? 0.7 : 1}}>
                    <NavBarConfigurer />
                    <h3>Component</h3>
                    <Button onClick={this.handleOpen}>+Add component </Button>
                    {this.state.compoents && <Table columns={this.columns} data={this.state.compoents} />}
                    {this.state.open && <ComponentPopup open={this.state.open} handleClose={this.handleClose} onSave={this.handleSave}/>}
                </div>
            </React.Fragment>
        );
    }
}
 
export default Components;