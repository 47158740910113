import React, { Component } from "react";
import {
  addToHistory,
  getConfirmed,
  pickedup,
  sendToDel,
} from "../../services/pharmacies";
import Table from "../common/table";
import Button from "@mui/material/Button";
import PrepareDialog from "./popup_dialog";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import CircularProgressIndictor from "../common/circular_progress";
import NavBarConfigurer from "./nav_bar_configurer";
import { Link } from "react-router-dom";

class PreparingForm extends Component {
  state = {
    data: [],
    open: false,
    snackbar: false,
    order: null,
    status: -1,
  };
  handleSnackClick = async (id) => {
    let data = this.state.data.filter((o) => o._id !== id);
    this.setState({ data });
    this.setState({ snackbar: true });
    await sendToDel(id);
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbar: false });
  };

  handleOpen = (order) => {
    this.setState({ open: true, order });
  };
  handleClose = async (id, history) => {
    if (typeof id == "string") {
      let data = this.state.data.filter((o) => o._id !== id);
      this.setState({ data });
      this.setState({ open: false, order: null });
      await addToHistory(id, history);
    }
    this.setState({ open: false, order: null });
  };
  handlePickUp = async (id) => {
    let data = this.state.data.filter((o) => o._id !== id);
    this.setState({ data });
    this.setState({ open: false });
    await pickedup(id);
  };
  columns = [
    {
      path: "custId",
      label: "Order id",
      // /pharmacist/indiv/:id
      content: (o) => (
        <Link
          style={{
            textDecoration: "none",
            fontFamily: "cursive",
            color: "#f44336",
          }}
          to={`/pharmacist/indiv/${o._id}`}
        >
          {o.custId}
        </Link>
      ),
    },
    {
      path: "cust_first_name",
      label: "Pat. Full-N.",
      content: (o) => (
        <React.Fragment>
          {o.cust_first_name} {o.cust_last_name}
        </React.Fragment>
      ),
    },
    {
      path: "type",
      label: "Order type",
    },
    {
      path: "branch",
      label: "Branch",
      content: (order) =>
        order.branch ? (
          <React.Fragment>{order.branch.name}</React.Fragment>
        ) : (
          <React.Fragment>No branch</React.Fragment>
        ),
    },
    {
      path: "",
      label: "Time taken",
      content: (order) => (
        <React.Fragment>
          {moment(Date.now()).diff(order.prescribed_date, "hours")} hours{" "}
          {moment(Date.now()).diff(order.prescribed_date, "minutes") % 60}{" "}
          minutes
        </React.Fragment>
      ),
    },
    {
      path: "",
      label: "Actions",
      content: (order) =>
        order.type == "pickup" ? (
          <Button
            variant="outlined"
            onClick={() => this.handleOpen(order)}
            color="warning"
          >
            prepared
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="warning"
            onClick={() => this.handleSnackClick(order._id)}
          >
            prepared
          </Button>
        ),
    },
  ];
  async componentDidMount() {
    this.setState({ status: 0 });
    let data = await getConfirmed();
    this.setState({ data, status: -1 });
  }
  render() {
    let action = (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={this.handleSnackClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          <h2
            className="m-2"
            style={{ textAlign: "center", fontFamily: "cursive" }}
          >
            Currently preparing
          </h2>
          <Table columns={this.columns} data={this.state.data} />
          {this.state.open && (
            <PrepareDialog
              open={this.state.open}
              order={this.state.order}
              onSuccess={this.handlePickUp}
              handleClose={this.handleClose}
            />
          )}
          <Snackbar
            open={this.state.snackbar}
            autoHideDuration={6000}
            onClose={this.handleSnackClose}
            message="driver is notified"
            action={action}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default PreparingForm;
