import React, { Component } from "react";
import Table from "../common/table";
import moment from "moment/moment";
import NavBarConfigurer from "./nav_bar_configurer";
import { getTerminated } from "../../services/pharmacies";
import { Button } from "@mui/material";
import CircularProgressIndictor from "../common/circular_progress";
import { Link } from "react-router-dom";

class Terminated extends Component {
  state = {
    orders: [],
    status: -1,
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let orders = await getTerminated();
    this.setState({ orders, status: -1 });
  }
  columns = [
    {
      path: "custId",
      label: "Order number",
      content: (o) => (
        <Link
          style={{
            textDecoration: "none",
            fontFamily: "cursive",
            color: "#f44336",
          }}
          to={`/pharmacist/indiv/${o._id}`}
        >
          {o.custId}
        </Link>
      ),
    },
    {
      path: "cust_first_name",
      label: "Pat. Full-N.",
      content: (o) => (
        <React.Fragment>
          {o.cust_first_name} {o.cust_last_name}
        </React.Fragment>
      ),
    },
    {
      path: "cust_phone_number",
      label: "Phone number",
    },
    {
      path: "prescribed_date",
      label: "Prescribed date",
      content: (order) => (
        <React.Fragment>
          {moment(order.prescribed_date).format("dddd, MMMM Do, h:mm")}
        </React.Fragment>
      ),
    },
    {
      path: "doctor.user.first_name",
      label: "Doctor",
    },
    {
      path: "hospital.name",
      label: "Hospital",
    },
    {
      path: "",
      label: "Time taken",
      content: (order) => (
        <React.Fragment>
          {moment(Date.now()).diff(order.prescribed_date, "hours")} hours{" "}
          {moment(order.delivered_date).diff(order.prescribed_date, "minutes") %
            60}{" "}
          minutes
        </React.Fragment>
      ),
    },
    {
      path: "termination_reason",
      label: "Reason",
      content: (order) => (
        <React.Fragment>
          {order.termination_reason == 0 ? "None" : order.termination_reason}
        </React.Fragment>
      ),
    },
    {
      path: "",
      label: "Action",
      content: (o) => <Button onClick>Roll back</Button>,
    },
  ];
  render() {
    return (
      <div>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          <h2 style={{ fontFamily: "cursive", textAlign: "center" }}>
            Terminated Orders
          </h2>
          <Table columns={this.columns} data={this.state.orders} />
        </div>
      </div>
    );
  }
}

export default Terminated;
