import http from "./httpService";
import config from "../config.json";

export async function getAllHospitals() {
  let result = await http.get(config.apiUrl + "/hospitals");
  return result.data;
}

export async function getDoctorsOfHospital(id) {
  let result = await http.get(config.apiUrl + "/doctors/hospitals/" + id);
  return result.data;
}

export async function getNewHospitals() {
  let result = await http.get(config.apiUrl + "/hospitals/new");
  return result.data;
}
export async function editHospital(id, name) {
  let result = await http.put(config.apiUrl + `/hospitals/${id}`, { name });
  return result.status;
}

export async function confirmHospital(id) {
  let result = await http.delete(config.apiUrl + `/hospitals/${id}/allow`);
  return result.status;
}
