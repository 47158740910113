import React, { Component } from "react";
import { getPendingOrders } from "../../services/pharmacies";
import NavBarConfigurer from "./nav_bar_configurer";
import PendingTable from "./pending_table";
import CircularProgressIndictor from "../common/circular_progress";

class PharmacistHomePage extends Component {
  state = {
    data: [],
    status: -1,
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let data = await getPendingOrders();
    this.setState({ data });
    this.setState({ status: -1 });
  }
  render() {
    return (
      <div>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div
          style={{
            position: "relative",
            backgroundColor: "white",
            opacity: this.state.status == 0 ? 0.7 : 1,
          }}
        >
          <NavBarConfigurer />
          <h3
            style={{
              textAlign: "center",
              marginTop: "25px",
              fontFamily: "cursive",
            }}
          >
            Pending orders
          </h3>
          <PendingTable data={this.state.data} />
        </div>
      </div>
    );
  }
}

export default PharmacistHomePage;
