import React from "react";
import Joi from "joi-browser";
import "../../App.css";
import Form from "../common/form";
import {
  addHospitalToMe,
  getBranches,
  getMe,
  prescribe,
} from "../../services/prescription";
import NavBarConfigurer from "./nav_bar_configurer";
import Button from "@mui/material/Button";
import PopUp from "../doctor/popup";
import GeneralizedMedicine from "../doctor/generalpopup";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import config from "../../config.json";
import CircularProgressIndictor from "../common/circular_progress";
import { getAllHospitals } from "../../services/hospitalServices";
import { prescribeWalking } from "../../services/walkingprescribe";
class Walking extends Form {
  state = {
    data: {
      cust_first_name: "",
      cust_last_name: "",
      cust_phone_number: "",
      type: "pick_up",
      hospital: "",
      area: "",
      sub_area: "",
      image: null,
      personalizedMedicines: [],
      generalizedMedicine: [],
      price: "",
    },
    errors: {},
    open: false,
    anotherOpen: false,
    image_link: null,
    status: -1,
    type: [
      { value: "delivery", label: "Delivery" },
      { value: "pick_up", label: "Pick up" },
    ],
    branches: [],
    medErr: null,
    components: [],
    collections: [],
  };
  schema = {
    cust_first_name: Joi.string().required().label("First Name"),
    cust_last_name: Joi.string().required().label("Last name"),
    cust_phone_number: Joi.string()
      .required()
      .min(10)
      .max(10)
      .regex(new RegExp("^[0-9]+$"))
      .label("Phone"),
    type: Joi.string().required(),
    hospital: Joi.string().min(5),
    area: Joi.string().optional().allow(""),
    sub_area: Joi.string().optional().allow(""),
    image: Joi.any(),
    personalizedMedicines: Joi.array().optional().allow([]),
    generalizedMedicine: Joi.array().optional().allow([]),
    price: Joi.number().required().min(0),
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let collections = await getAllHospitals();
    this.setState({ collections, status: -1 });
  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleAnotherOpen = () => {
    this.setState({ anotherOpen: true });
  };
  handleAnotherClose = () => {
    this.setState({ anotherOpen: false });
  };
  handleChoice = (hospital) => {
    let data = { ...this.state.data };
    data.hospital = hospital;
    this.setState({ data });
  };
  doSubmit = async () => {
    this.setState({ status: 0 });
    let res = await prescribeWalking(this.state.data, this.state.image_link);
    return this.props.history.push("/pharmacist/preparing");
  };
  areaForm = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-3">{this.renderInput("area", "Area")}</div>
          <div className="col-3">
            {this.renderInput("sub_area", "Sub area")}
          </div>
        </div>
      </React.Fragment>
    );
  };
  branchForm = () => {
    return (
      <React.Fragment>
        {this.renderRadio("branch", "Pick up branch", this.state.branches)}
      </React.Fragment>
    );
  };
  fileData = () => {
    if (this.state.data.image && this.state.image_link) {
      return (
        <div>
          <h2>File Details:</h2>
          <img
            style={{ maxWidth: "700px", width: "80%" }}
            src={config.image + this.state.image_link}
          />
          <p>File Type: {this.state.data.image.type}</p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <em style={{ color: "red" }}>Prescription not added</em>
        </div>
      );
    }
  };
  addPersonalizedMedicine = (newData, components) => {
    if (this.state.components.length == 0) {
      this.setState({ components });
    }
    let data = { ...this.state.data };
    data.personalizedMedicines.push(newData);
    this.setState({ data });
    this.handleClose();
  };
  handleGeneralizedMedicine = (medicine) => {
    if (medicine.length >= 50 || medicine.length < 3) {
      this.setState({ medErr: "Medicine is of invalid length" });
      return;
    }
    let data = { ...this.state.data };
    data.generalizedMedicine.push(medicine);
    this.setState({ data });
    this.handleAnotherClose();
  };
  render() {
    return (
      <React.Fragment>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div
          style={{
            backgroundColor: "#efefef",
            position: "relative",
            opacity: this.state.status == 0 ? 0.7 : 1,
          }}
        >
          <NavBarConfigurer />
          <div className="d-flex justify-content-center">
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "15px",
                marginBottom: "30px",
              }}
              className="my-2 padded"
            >
              <div className="text-center">
                <h2 style={{ fontFamily: "cursive" }}>
                  Walk-in customer order form
                </h2>
              </div>
              <form>
                <div style={{ margin: "25px 0" }}>
                  <h3 style={{ fontFamily: "cursive" }}>Patient information</h3>
                  <div className="row">
                    <div style={{ minWidth: "220px" }} className="col-3">
                      {this.renderInput("cust_first_name", "First name *")}
                    </div>
                    <div style={{ minWidth: "220px" }} className="col-3">
                      {this.renderInput("cust_last_name", "Last name *")}
                    </div>
                  </div>
                  <div className="width_setter">
                    {this.renderInput("cust_phone_number", "Phone number *")}
                  </div>
                </div>
                <div className="my-4">
                  {this.renderRadio(
                    "type",
                    <h3 style={{ fontFamily: "cursive" }}>Pick up options</h3>,
                    this.state.type
                  )}
                  {this.state.data.type == "delivery" && this.areaForm()}
                </div>
                <Autocomplete
                  freeSolo
                  onInputChange={(event, newValue) =>
                    this.handleChoice(newValue)
                  }
                  options={this.state.collections.map((hos) => hos.name)}
                  renderInput={(params) => {
                    return <TextField label="Hospital" {...params} />;
                  }}
                />
                {this.state.errors.hospital && (
                  <p style={{ color: "red" }}>Hospital is of invalid length</p>
                )}
                <hr />
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={this.handleOpen}
                >
                  + Add compounding (optional)
                </Button>
                {this.state.open && (
                  <PopUp
                    open={this.state.open}
                    handleClose={this.handleClose}
                    onSave={this.addPersonalizedMedicine}
                  />
                )}
                <ol>
                  {this.state.data.personalizedMedicines.map((p) => {
                    return (
                      <p>
                        <li>
                          <ol type="A">
                            {Object.keys(p).map((a) => {
                              // console.log(this.state.components.findIndex(ad=> a===ad._id))
                              let c = this.state.components.find(
                                (ad) => ad._id == a
                              );
                              return (
                                <li>
                                  {c.name}-- {p[a][0]} {p[a][1]}
                                </li>
                              );
                            })}
                          </ol>
                        </li>
                      </p>
                    );
                  })}
                </ol>
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={this.handleAnotherOpen}
                  onSave={this.handleGeneralizedMedicine}
                >
                  + Add general medicine (optional)
                </Button>
                {this.state.anotherOpen && (
                  <GeneralizedMedicine
                    medErr={this.state.medErr}
                    open={this.state.anotherOpen}
                    handleClose={this.handleAnotherClose}
                    onSave={this.handleGeneralizedMedicine}
                  />
                )}
                <ol>
                  {this.state.data.generalizedMedicine.map(
                    (generalizedMedicine) => {
                      return <li>{generalizedMedicine}</li>;
                    }
                  )}
                </ol>
                <h4 style={{ fontFamily: "cursive" }}>Prescription image</h4>
                <div>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      name="image"
                      onChange={this.onFileChange}
                    />
                    <PhotoCamera />
                  </IconButton>
                  {/* <input accept="image/*" type="file"  name='image' onChange={this.onFileChange}/> */}
                </div>
                {this.fileData()}

                {this.renderInput("price", "Price", "number")}
                {this.renderButton("Submit")}
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Walking;

// import React, { Component } from 'react';

// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import { getAllHospitals } from '../../services/hospitalServices';
// import { addHospitalToMe } from '../../services/prescription';
// import { Autocomplete, TextField } from '@mui/material';
// import CircularProgressIndictor from '../common/circular_progress';

// class AddHospital extends Component {
//     state = {
//         hospital: '',
//         collections:[],
//         error: null,
//         status:-1
//     }
//     handleChoice=(hospital)=>{
//         this.setState({hospital})
//      }
//     async componentDidMount(){
//         this.setState({status:0})
//         this.props.onOpen()
//         let collections = await getAllHospitals()
//         this.setState({collections,status:-1})
//      }
//     handleSave= async()=>{
//       if(this.state.hospital.length>50 || this.state.hospital.length<4){
//           this.setState({
//             error: "Hospital is of invalid length"
//           })
//           return
//       }
//       this.props.onSave(this.state.hospital);
//     }
//     render() {
//         let {open, handleClose} =this.props
//         return (
//             <div>

//             <Dialog open={open} onClose={handleClose}>
//               {this.state.status==0? <div >
//                     <CircularProgressIndictor />
//               </div>:
//               <React.Fragment>
//               <DialogTitle>Choose hospital</DialogTitle>
//               <DialogContent>
//                 <DialogContentText>
//                  Please enter the name of the hospital
//                 </DialogContentText>
//                 <Autocomplete
//                         id="free-solo-demo"
//                         freeSolo
//                         value={this.state.hospital}
//                         onInputChange={(event,newValue)=> this.handleChoice(newValue)}
//                         options={this.state.collections.map((option) => option.name)}
//                         renderInput={(params)=>{
//                             return <TextField {...params} />}}
//                     />
//                   {this.state.error && <p style={{color:'red'}}>Hospital is of invalid length</p>}
//               </DialogContent>
//               <DialogActions>
//                 <Button onClick={handleClose}>Cancel</Button>
//                 <Button onClick={this.handleSave}>Save</Button>
//               </DialogActions>
//               </React.Fragment>
//               }
//             </Dialog>
//           </div>
//         );
//     }
// }

// export default AddHospital;
