import http from "./httpService";
import config from "../config.json";
import _ from "lodash";

export async function getAllPromotions() {
  let result = await http.get(config.apiUrl + "/promotions");
  return result.data;
}
export async function addPromotion(data, image_link) {
  let body = _.pick(data, ["title", "description"]);
  body.image_link = image_link;
  let result1 = await http.post(config.apiUrl + "/promotions", body);
}

export async function deletePromotions(id) {
  let result = await http.delete(config.apiUrl + "/promotions/" + id);
  return result;
}
