import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { deActivate, getAllUsers } from '../../services/usersServices';
import { paginate } from '../../utils/pageinate';
import ListGroup from '../common/listGroup';
import Pagination from '../common/pagination';
import SearchBox from '../common/search';
import NavBarConfigurer from './nav_bar_configurred';
import UserTable from './user_table';
import Button from '@mui/material/Button'
import CircularProgressIndictor from '../common/circular_progress';
import auth from '../../services/authService';

class AdminUser extends Component {
    state = { 
        users:[],
        roles: [{_id:'', name: 'All roles'},{_id:'doctor', name:'Doctors'},{_id:'driver', name: 'Drivers'}, {_id:'pharmacist', name:'Pharmacists'},{_id:'admin', name: 'Admins'}],
        currentPage:1,
        pageSize:10,
        searchQuery:"",
        selectedRole: null,
        status:-1,
        currUserRole: null
     } 
    async componentDidMount(){
      let currUserRole = auth.getCurrentUser().role
        this.setState({currUserRole})
        this.setState({status:0})
        let data = await getAllUsers();
        this.setState({users: data, status:-1})
    }
    handlePageChange = page => {
        this.setState({ currentPage: page });
    };
    handleRoleSelect = role => {
      this.setState({ selectedRole: role, currentPage: 1 });
    };
    handleSearch = query => {
        this.setState({ searchQuery: query,selectedRole:null ,currentPage: 1 });
    };
    getPagedData = () => {
        const {
          pageSize,
          currentPage,
          searchQuery,
          selectedRole,
          users: allUsers
        } = this.state;
    
        let filtered = allUsers;
        if (searchQuery)
          filtered = allUsers.filter(m =>
            m.first_name.toLowerCase().startsWith(searchQuery.toLowerCase()) ||  m.last_name.toLowerCase().startsWith(searchQuery.toLowerCase())
          );
          else if (selectedRole && selectedRole._id)
          filtered = allUsers.filter(m => m.role === selectedRole._id);

        const users = paginate(filtered, currentPage, pageSize);
    
        return { totalCount: filtered.length, data: users };
      };
    activate=async (id)=>{
        let oldUsers= [...this.state.users]
        let newUsers= oldUsers.filter(user => user._id!==id)
        this.setState({users: newUsers})
        let status =await deActivate(id)
        if (!status || status>=400)
        this.setState({users: oldUsers})
      }
      style= {
        color: 'black',
        textDecoration: 'none'
      }
    render() { 
        const { pageSize, currentPage,  searchQuery } = this.state;
        const { totalCount, data: users } = this.getPagedData(); 
        return (
            <div>
              <div style={{display: this.state.status==0? 'block' : 'none'}}>
                    <CircularProgressIndictor />
                </div>
                <div style={{opacity: this.state.status==0? 0.7 : 1}}>
            <NavBarConfigurer />
           <div className='row'>
           <div className='col-4 m-2'>
              <ListGroup
              items={this.state.roles}
              selectedItem={this.state.selectedRole}
              onItemSelect={this.handleRoleSelect}
            />
            </div>
            <div className='col'>
              <h1>User</h1>
              
              <Link to='/admin/users/inactive'><Button>Inactive users</Button></Link>
              <br />
              <Button variant='contained' color='warning'><Link to='/admin/users/new' style={this.style}>+ Add user</Link></Button>
              <SearchBox value={searchQuery} onChange={this.handleSearch} />
              <UserTable data={users} onActivate={this.activate} currUserRole={this.state.currUserRole}/>
              <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
            </div>
           </div>
            </div>
            </div>
        );
    }
}
 
export default AdminUser;