import React, { Component } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getAllHospitals } from '../../services/hospitalServices';
import { addHospitalToMe } from '../../services/prescription';
import { Autocomplete, TextField } from '@mui/material';
import CircularProgressIndictor from '../common/circular_progress';

class AddHospital extends Component {
    state = { 
        hospital: '',
        collections:[],
        error: null,
        status:-1
    } 
    handleChoice=(hospital)=>{
        this.setState({hospital})
     }
    async componentDidMount(){
        this.setState({status:0})
        this.props.onOpen()
        let collections = await getAllHospitals()
        this.setState({collections,status:-1})
     }
    handleSave= async()=>{
      if(this.state.hospital.length>50 || this.state.hospital.length<4){
          this.setState({
            error: "Hospital is of invalid length"
          })
          return
      }
      this.props.onSave(this.state.hospital);
    }
    render() { 
        let {open, handleClose} =this.props
        return (
            <div>
              
            <Dialog open={open} onClose={handleClose}>
              {this.state.status==0? <div >
                    <CircularProgressIndictor />
              </div>:
              <React.Fragment>
              <DialogTitle>Choose hospital</DialogTitle>
              <DialogContent>
                <DialogContentText>
                 Please enter the name of the hospital
                </DialogContentText>
                <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        value={this.state.hospital}
                        onInputChange={(event,newValue)=> this.handleChoice(newValue)}
                        options={this.state.collections.map((option) => option.name)}
                        renderInput={(params)=>{
                            return <TextField {...params} />}}
                    />
                  {this.state.error && <p style={{color:'red'}}>Hospital is of invalid length</p>}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={this.handleSave}>Save</Button>
              </DialogActions>
              </React.Fragment>
              }
            </Dialog>
          </div>
        );
    }
}
 
export default AddHospital;
