import http from "./httpService";
import config from '../config.json'

export async function Inquire(data){
    let result = await http.post(config.apiUrl + `/inquiries`, {email: data.email, phoneNumber: data.phoneNumber, message: data.message})
    return result.status
} 

export async function getInquiries(){
    let result= await http.get(config.apiUrl +'/inquiries')
    return result.data
}