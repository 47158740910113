import React, { Component } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class CategoryPopUp extends Component {
    state = { 
        name: '',
        measurement_type:''
     } 
     handleName=(e)=>{
        let name = e.target.value
        this.setState({name})
     }
    //  handleMeadurement=(e)=>{
    //     let measurement_type = e.target.value
    //     this.setState({measurement_type})
    //  }
    render() { 
        let {open, handleClose,onSave} =this.props
        return (
            <div>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Component</DialogTitle>
              <DialogContent>
                <DialogContentText>
                 Please enter the name of the component
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Component name"
                  fullWidth
                  variant="standard"
                  maxRows={3}
                  onChange={this.handleName}
                />
                {/* <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Measurement type"
                  fullWidth
                  variant="standard"
                  maxRows={3}
                  onChange={this.handleMeadurement}
                /> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={()=> onSave(this.state.name)}>Save</Button>
              </DialogActions>
            </Dialog>
          </div>
        );
    }
}
 
export default CategoryPopUp;
