import React from "react";
import '../../App.css'
import {InputBase, IconButton, Divider} from '@mui/material'
import {SearchSharp} from '@mui/icons-material'
import Paper from '@mui/material/Paper';

const SearchBox = ({ value, onChange }) => {
  return (
   <div className="searchbox">
       <Paper
          component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
     <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search "
        onChange={(e)=>onChange(e.currentTarget.value)}
        value={value}
    />
    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
    <SearchSharp />
    </IconButton>
    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
    </Paper>
   </div>
  );
};

export default SearchBox;
