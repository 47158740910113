import React from "react";

const Select = ({ name, label, options,errors,onChange, value }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <select name={name} id={name} onChange={onChange} className="form-control">
        {options.map(option => (
          <option selected={value===option._id} key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </select>
      {errors && <p style={{color: 'red'}}>Other is invalid option</p>}
    </div>
  );
};

export default Select;