import jwtDecode from "jwt-decode";
import http from "./httpService";
import config from "../config.json";

const tokenKey = "token";

http.setJwt(getJwt());

async function login(email, password) {
  const { data } = await http.post(config.apiUrl + "/auth", {
    email,
    password,
  });
  localStorage.setItem("token", data.token);
}

function logout() {
  localStorage.removeItem("token");
  window.location("/");
}

function getCurrentUser() {
  try {
    const jwt = localStorage.getItem("token");
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

function getJwt() {
  return localStorage.getItem("token");
}

const auth = {
  login,
  logout,
  getCurrentUser,
  getJwt,
};
export default auth;
