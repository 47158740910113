import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService";
const AdminRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        let curr_user = auth.getCurrentUser();
        if (
          !curr_user ||
          (curr_user.role != "admin" && curr_user.role != "superadmin")
        ) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
        }

        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default AdminRoute;
