import React, { Component } from 'react';
import { getHospitalsOfDoctor } from '../../services/doctorServices';
import HospitalTable from './hospital_table';
import NavBarConfigurer from './nav_bar_configurred';
import CircularProgressIndictor from '../common/circular_progress';

class HospitalsDocWorksIn extends Component {
    state = { 
        data: [],
        status:-1
     } 
    async componentDidMount(){
        this.setState({status:0})
        let data = await getHospitalsOfDoctor(this.props.match.params.id)
        this.setState({data, status:-1})
    }
    render() { 
        return (
            <div>
                <div style={{display: this.state.status==0? 'block' : 'none'}}>
                    <CircularProgressIndictor />
                </div>
                <div style={{opacity: this.state.status==0? 0.7 : 1}}>
                <NavBarConfigurer />
                <HospitalTable data={this.state.data} />
                </div>
            </div>
        );
    }
}
 
export default HospitalsDocWorksIn;


