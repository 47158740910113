import http from "./httpService";
import config from "../config.json";

export async function getData(year) {
  let result;
  result = await http.get(config.apiUrl + "/graph/" + year);

  let unFiltreed_data = result.data;
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let data = [];
  for (let month of months) {
    data.push({
      name: month,
      preparing: 0,
      terminated: 0,
      delivered: 0,
      pickedup: 0,
    });
  }
  for (let order of unFiltreed_data) {
    let month = new Date(order.prescribed_date).getMonth();
    switch (order.curr_status) {
      case "terminated":
        data[month]["terminated"] += 1;
        break;
      case "delivered":
        data[month]["delivered"] += 1;
        break;
      case "pickedup":
        data[month]["pickedup"] += 1;
        break;
      default:
        data[month]["preparing"] += 1;
    }
  }
  return data;
}

export async function getBranchData(year) {
  let result;
  result = await http.get(config.apiUrl + "/graph/branch/" + year);
  let unFiltered_data = result.data;
  result = { Unknown: 0 };
  for (let data of unFiltered_data) {
    if (!data.branch) result["Unknown"] += 1;
    else if (!(data.branch.name in result)) {
      result[data.branch.name] = 1;
    } else if (data.branch.name in result) {
      result[data.branch.name] += 1;
    }
  }
  let another_list = [];
  for (let r in result) {
    another_list.push({ name: r, value: result[r] });
  }
  return another_list;
}
