import React, { Component } from "react";
import Joi from "joi-browser";
import NavBarConfigurer from "./nav_bar_configurer";
import Form from "../common/form";
import { Grid } from "@mui/material";
import image from "./image_two.jpeg";
import CircularProgressIndictor from "../common/circular_progress";
import { Inquire } from "../../services/inquire";
class ContactUs extends Form {
  state = {
    data: { email: "", phoneNumber: "", message: "" },
    errors: {},
    status: -1,
  };

  schema = {
    email: Joi.string().required().label("Email").email(),
    phoneNumber: Joi.string()
      .required()
      .min(10)
      .max(10)
      .regex(new RegExp("^[0-9]+$"))
      .label("Phone"),
    message: Joi.string().min(5).required().max(150),
  };
  doSubmit = async () => {
    this.setState({ status: 0 });
    await Inquire(this.state.data);
    this.setState({ status: -1 });
    this.props.history.push("/doctor/home");
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div
          style={{
            position: "relative",
            opacity: this.state.status == 0 ? 0.7 : 1,
          }}
        >
          <NavBarConfigurer />
          <Grid container>
            <Grid className="p-3" item xs={10} md={5} lg={5} xl={4}>
              <h3>Contact information</h3>
              <h4 style={{ textAlign: "center" }}>For any inquiries</h4>
              <img src={image} style={{ width: "100%" }} />
              <p>
                <h6>Email:</h6>
                <a href="mailto:example@example.com">example@example.com</a>
              </p>
              <p>
                <h6>Phone-number:</h6>
                <a href="tel:0900000000">0900000000</a>
              </p>
            </Grid>
            <Grid className="p-3" item xs={10} md={6} lg={6} xl={7}>
              <div
                style={{
                  margin: "30px 5px",
                  borderRadius: "5px",
                  border: "1px solid black",
                  padding: "30px",
                }}
              >
                <h4>Any comments</h4>
                <div>{this.renderInput3("email", "Email*", "email")}</div>
                <div>
                  {this.renderInput3("phoneNumber", "Phone-number*", "tel")}
                </div>
                <div>
                  {this.renderTextArea("message", "Type your inquiries*")}
                </div>
                <div>{this.renderButton("Submit")}</div>
              </div>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactUs;
