import React, { Component } from 'react';
import Table from '../common/table';
import {Link} from 'react-router-dom';

class DoctorTable extends Component {
    state = {  }
    columns = [
        {
          path: "user.first_name",
          label: "First name",
        },
        {
            path: "user.last_name",
            label: "Last name",
        },
        {
            path: "user.phoneNumber",
            label: "Phone number",
        },
        { 
            path: "hospitals", label: "Hospitals",
            content: doctor => doctor.hospitals.length >0? <Link to={`/admin/hospitals/doctors/${doctor._id}`}>{doctor.hospitals.length}</Link> : <React.Fragment>{doctor.hospitals.length}</React.Fragment>
        },
        { 
            path: "order_count", label: "Orders",
            content: doctor =>doctor.order_count>0? <Link to={`/admin/prescriptions/doctors/${doctor._id}`}>{doctor.order_count}</Link>: <React.Fragment>{doctor.order_count}</React.Fragment>
        },
        { 
            path: "specialization.name", label: "Specializations",
        },
        
      ]; 
    render() { 
        let {data} =this.props
        return (
            <div>
                <Table columns={this.columns} data={data} />
                
            </div>
        );
    }
}
 
export default DoctorTable;