import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
const ListGroup = ({
  items,
  textProperty,
  valueProperty,
  selectedItem,
  onItemSelect,
}) => {
  return (
    <ul className="list-group">
      {items.map((item) => (
        <Box
          sx={{
            bgcolor: item === selectedItem ? "warning.main" : "text.secondary",
            color: "warning.contrastText",
          }}
        >
          <ListItemButton onClick={() => onItemSelect(item)}>
            <ListItemText primary={item[textProperty]} />
          </ListItemButton>
        </Box>
        // <li
        //   onClick={() => onItemSelect(item)}
        //   key={item[valueProperty]}
        //   className={
        //     item === selectedItem ? "list-group-item active" : "list-group-item clickable"
        //   }
        // >
        //   {item[textProperty]}
        // </li>
      ))}
    </ul>
  );
};

ListGroup.defaultProps = {
  textProperty: "name",
  valueProperty: "_id",
};

export default ListGroup;
