import React, { Component } from 'react';
import Table from '../common/table';
import {Link} from 'react-router-dom';
import {Edit} from '@mui/icons-material';
import Button from '@mui/material/Button'
import moment from 'moment/moment'
import ResetPassword from './resetPassword';
import { resetPassword } from '../../services/usersServices';
class UserTable extends Component {
    state = { 
        currentPage:1,
        pageSize: 4,
        open: false,
        currUser: null
     }

    handleClose=()=>{
        this.setState({open: false, currUser:null})
    }
    handleOpen=(user)=>{
        this.setState({open: true, currUser: user})
    }
    handleSave=async (id, password)=>{
        this.handleClose()
        await resetPassword(id, password)
    }
    columns = [
        {
          path: "first_name",
          label: "First name",
        },
        {
            path: "last_name",
            label: "First name",
        },
        {
            path: "email",
            label: "Email",
        },
        {
            path: "phoneNumber",
            label: "Phone number",
        },
        {
            path: "role",
            label: "Role",
            content: user => user.role==='superadmin'? 'Admin' : user.role==='admin'? 'manager': user.role
        },
        { 
            path: "register_date", 
            label: "Register date",  
            content: user=> <React.Fragment>{moment(user.register_date).format("YYYY-MM-DD HH:mm:ss")}</React.Fragment>
        },
        { 
            path: "actions", 
            label: "Edit",  
            content: user => user.role==='doctor'|| (this.props.currUserRole!='superadmin' && user.role=='superadmin') ? 'No action' :<Link to={`/admin/users/${user._id}`}><Edit color='warning'/></Link>
        },
        { 
            path: "activate", 
            label: "Activation",  
            content: user => <Button color='warning' disabled={this.props.currUserRole!='superadmin' && user.role=='superadmin'} onClick={()=> this.props.onActivate(user._id)}>{user.isActive ? 'deactivate' : 'activate'}</Button>
        },
        {
            path:'',
            label: '',
            content: user => this.props.currUserRole=='superadmin'? <Button onClick={()=> this.handleOpen(user)}> Reset password</Button>: '',
        }
      ]; 
    render() { 
        let {data} =this.props

        return (
            <div>
                <Table columns={this.columns} data={data} />
                {this.state.open && <ResetPassword  handleClose={this.handleClose} open={this.state.open} user={this.state.currUser} onSave={this.handleSave}/>}
            </div>
        );
    }
}
 
export default UserTable;