import React from 'react';


const Radio=({name, legend,value,options, onChange}) =>{
    return (
        <div>
            <legend>{legend}</legend>
            <div className="form-group d-sm-flex">
            {options.map(option => {
                    return <div>
                            <input type='checkbox' name={name} onChange={onChange} value={option.value} checked={value== option.value} />
                            <label style={{paddingLeft: '10px', paddingRight: '25px'}}>{option.label}</label>
                    </div>
                    })}
            </div>
        </div>
       
    )
}

export default Radio