import React from "react";
import Joi from "joi-browser";
import "../../App.css";
import Form from "../common/form";
import {
  addHospitalToMe,
  getBranches,
  getMe,
  prescribe,
} from "../../services/prescription";
import NavBarConfigurer from "./nav_bar_configurer";
import Button from "@mui/material/Button";
import PopUp from "./popup";
import GeneralizedMedicine from "./generalpopup";
import AddHospital from "./addhospital";
import { IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import config from "../../config.json";
import CircularProgressIndictor from "../common/circular_progress";
class Prescription extends Form {
  state = {
    data: {
      cust_first_name: "",
      cust_last_name: "",
      cust_phone_number: "",
      type: "not_decided",
      hospital: "",
      area: "",
      sub_area: "",
      image: null,
      personalizedMedicines: [],
      generalizedMedicine: [],
      branch: null,
      doc_comments: "",
    },
    errors: {},
    hospitals: [],
    open: false,
    anotherOpen: false,
    hospitalOpen: false,
    image_link: null,
    status: -1,
    type: [
      { value: "not_decided", label: "Not decided yet" },
      { value: "delivery", label: "Delivery" },
      { value: "pick_up", label: "Pick up" },
    ],
    branches: [],
    medErr: null,
    components: [],
  };
  schema = {
    cust_first_name: Joi.string().required().label("First Name"),
    cust_last_name: Joi.string().required().label("Last name"),
    cust_phone_number: Joi.string()
      .required()
      .min(10)
      .max(10)
      .regex(new RegExp("^[0-9]+$"))
      .label("Phone"),
    type: Joi.string().required(),
    hospital: Joi.string().min(8),
    area: Joi.string().optional().allow(""),
    sub_area: Joi.string().optional().allow(""),
    doc_comments: Joi.string().optional().allow("").max(100),
    branch: Joi.string(),
    image: Joi.any(),
    personalizedMedicines: Joi.array().optional().allow([]),
    generalizedMedicine: Joi.array().optional().allow([]),
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let body = await getMe();
    let data = { ...this.state.data };
    this.setState({
      hospitals: [...body.hospitals, { _id: "12", name: "other" }],
    });
    data.hospital = body.hospitals[0]._id;
    let branchesA = await getBranches();
    let branches = branchesA.map(({ name: label, _id: value }) => ({
      label,
      value,
    }));
    this.setState({ branches });
    data.branch = branchesA[0]._id;
    this.setState({ data });
    this.setState({ status: -1 });
  }
  // needs to be corrected
  reloadHospital = async (h) => {
    this.handleHospitalClose();
    this.setState({ status: 0 });
    let hospital = await addHospitalToMe(h);
    this.setState({ hospitals: [hospital, ...this.state.hospitals] });
    let data = { ...this.state.data };
    data.hospital = hospital._id;
    this.setState({ data, status: -1 });
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleAnotherOpen = () => {
    this.setState({ anotherOpen: true });
  };
  handleAnotherClose = () => {
    this.setState({ anotherOpen: false });
  };
  handleHospitalOpen = () => {
    this.setState({ hospitalOpen: true });
  };
  handleHospitalClose = () => {
    this.setState({ hospitalOpen: false });
  };
  doSubmit = async () => {
    this.setState({ status: 0 });
    await prescribe(this.state.data, this.state.image_link);
    this.setState({ status: -1 });
    this.props.history.replace("/doctor/home");
    return;
  };
  areaForm = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-3">{this.renderInput("area", "Area")}</div>
          <div className="col-3">
            {this.renderInput("sub_area", "Sub area")}
          </div>
        </div>
      </React.Fragment>
    );
  };
  branchForm = () => {
    return (
      <React.Fragment>
        {this.renderRadio("branch", "Pick up branch", this.state.branches)}
      </React.Fragment>
    );
  };
  fileData = () => {
    if (this.state.data.image && this.state.image_link) {
      return (
        <div>
          <h2>File Details:</h2>
          <img
            style={{ maxWidth: "700px", width: "80%" }}
            src={config.image + this.state.image_link}
          />
          <p>File Type: {this.state.data.image.type}</p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <em style={{ color: "red" }}>Prescription not added</em>
        </div>
      );
    }
  };
  addPersonalizedMedicine = (newData, components) => {
    if (this.state.components.length == 0) {
      this.setState({ components });
    }
    let data = { ...this.state.data };
    data.personalizedMedicines.push(newData);
    this.setState({ data });
    this.handleClose();
  };
  handleGeneralizedMedicine = (medicine) => {
    if (medicine.length >= 50 || medicine.length < 3) {
      this.setState({ medErr: "Medicine is of invalid length" });
      return;
    }
    let data = { ...this.state.data };
    data.generalizedMedicine.push(medicine);
    this.setState({ data });
    this.handleAnotherClose();
  };
  render() {
    return (
      <React.Fragment>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div
          style={{
            backgroundColor: "#efefef",
            position: "relative",
            opacity: this.state.status == 0 ? 0.7 : 1,
          }}
        >
          <NavBarConfigurer />
          <div className="d-flex justify-content-center">
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "15px",
                marginBottom: "30px",
              }}
              className="my-2 padded"
            >
              <div className="text-center" style={{ marginBottom: "20px" }}>
                <h2
                  style={{
                    fontWeight: "normal",
                    fontSize: "28",
                    textDecoration: "underline black solid 1px",
                  }}
                >
                  <pre> Prescribe for your patients </pre>
                </h2>
              </div>
              <form>
                <div className="my-4">
                  <h4 style={{ fontWeight: "normal", fontStyle: "italic" }}>
                    Patient information
                  </h4>
                  <div className="row">
                    <div style={{ minWidth: "220px" }} className="col-3">
                      {this.renderInput("cust_first_name", "First name *")}
                    </div>
                    <div style={{ minWidth: "220px" }} className="col-3">
                      {this.renderInput("cust_last_name", "Last name *")}
                    </div>
                  </div>
                  <div className="width_setter">
                    {this.renderInput("cust_phone_number", "Phone number *")}
                  </div>
                </div>
                <div className="my-3">
                  {this.renderRadio(
                    "type",
                    <h4 style={{ fontWeight: "normal", fontStyle: "italic" }}>
                      Pick up options
                    </h4>,
                    this.state.type
                  )}
                </div>
                {this.state.data.type == "delivery" && this.areaForm()}
                {this.state.data.type == "pick_up" && this.branchForm()}
                <div className="width_setter">
                  {this.renderSelect(
                    "hospital",
                    <h4 style={{ fontWeight: "normal", fontStyle: "italic" }}>
                      Hospital Inforamtion
                    </h4>,
                    this.state.hospitals
                  )}
                </div>

                {this.state.data.hospital === "12" && (
                  <AddHospital
                    open={this.state.hospitalOpen}
                    onSave={this.reloadHospital}
                    onOpen={this.handleHospitalOpen}
                    handleClose={this.handleHospitalClose}
                  />
                )}
                <hr />
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={this.handleOpen}
                >
                  + Add compounding (optional)
                </Button>
                {this.state.open && (
                  <PopUp
                    open={this.state.open}
                    handleClose={this.handleClose}
                    onSave={this.addPersonalizedMedicine}
                  />
                )}
                <ol>
                  {this.state.data.personalizedMedicines.map((p) => {
                    return (
                      <p>
                        <li>
                          <ol type="A">
                            {Object.keys(p).map((a) => {
                              // console.log(this.state.components.findIndex(ad=> a===ad._id))
                              let c = this.state.components.find(
                                (ad) => ad._id == a
                              );
                              return (
                                <li>
                                  {c.name}-- {p[a][0]} {p[a][1]}
                                </li>
                              );
                            })}
                          </ol>
                        </li>
                      </p>
                    );
                  })}
                </ol>
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={this.handleAnotherOpen}
                  onSave={this.handleGeneralizedMedicine}
                >
                  + Add general medicine (optional)
                </Button>
                {this.state.anotherOpen && (
                  <GeneralizedMedicine
                    medErr={this.state.medErr}
                    open={this.state.anotherOpen}
                    handleClose={this.handleAnotherClose}
                    onSave={this.handleGeneralizedMedicine}
                  />
                )}
                <ol>
                  {this.state.data.generalizedMedicine.map(
                    (generalizedMedicine) => {
                      return <li>{generalizedMedicine}</li>;
                    }
                  )}
                </ol>
                <h4 style={{ fontWeight: "normal", fontStyle: "italic" }}>
                  Prescription image
                </h4>
                <div>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      name="image"
                      onChange={this.onFileChange}
                    />
                    <PhotoCamera />
                  </IconButton>
                  {/* <input accept="image/*" type="file"  name='image' onChange={this.onFileChange}/> */}
                </div>
                {this.fileData()}
                <div className="width_setter">
                  {this.renderInput("doc_comments", "Any comments")}
                </div>
                {this.renderButton("Submit")}
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Prescription;
