import React, { Component } from 'react';
import NavBar from '../common/navbar';
class NavBarConfigurer extends Component {
    links= [
      {label: 'Home', path:'/admin/home'},
      {label: 'Hospitals', path:'/admin/hospitals'},
      {label: 'Doctors',path:'/admin/doctors'},
      {label: 'Users', path: '/admin/users'},
      {label: 'Orders', path: '/admin/orders'},
      {label: 'Promotions', path:'/admin/promotions'},
      {label: 'Components', path: '/admin/components'},
      {label: 'Branches', path: '/admin/branches'},
      {label: 'Comments', path:'/admin/inquiries'},
      {}
    ]
  render() { 
    return (
      <NavBar links={this.links} />
    );
  }
}
 
export default NavBarConfigurer;