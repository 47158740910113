import React, { Component } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { searchMedicine } from '../../services/prescription';
import { Autocomplete } from '@mui/material';

class GeneralizedMedicine extends Component {
    state = { 
        medicine: '',
        options: []
     } 
     handleChange=async (medicine)=>{
        this.setState({medicine})
        let options =await searchMedicine(medicine)
        this.setState({options})
     }
     async componentDidMount(){
      let options =await searchMedicine('')
      this.setState({options})
     }
    render() { 
        let {open, handleClose,onSave, medErr} =this.props
        return (
            <div>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Medicine</DialogTitle>
              <DialogContent>
                <DialogContentText>
                 Please enter the name of the medicine
                </DialogContentText>
                <Autocomplete 
                  freeSolo
                  onInputChange={(event,newValue)=> this.handleChange(newValue)}
                  options={this.state.options}
                  renderInput={(params)=>{
                      return <TextField label="Medicine" {...params} />}}
              />
                {medErr && <p style={{color: 'red'}}>Hospital is of invalid length</p>}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={()=> onSave(this.state.medicine)}>Save</Button>
              </DialogActions>
            </Dialog>
          </div>
        );
    }
}
 
export default GeneralizedMedicine;
