import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getAllHospitals } from "../../services/hospitalServices";
import { paginate } from "../../utils/pageinate";
import Pagination from "../common/pagination";
import SearchBox from "../common/search";
import HospitalTable from "./hospital_table";
import NavBarConfigurer from "./nav_bar_configurred";
import CircularProgressIndictor from "../common/circular_progress";

class Hospital extends Component {
  state = {
    hositals: [],
    currentPage: 1,
    pageSize: 10,
    searchQuery: "",
    open: false,
    status: -1,
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let data = await getAllHospitals();
    this.setState({ hositals: data, status: -1 });
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };
  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };
  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      searchQuery,
      hositals: allHospitals,
    } = this.state;

    let filtered = allHospitals;
    if (searchQuery)
      filtered = allHospitals.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    const hospitals = paginate(filtered, currentPage, pageSize);

    return { totalCount: filtered.length, data: hospitals };
  };
  render() {
    const { pageSize, currentPage, searchQuery } = this.state;
    const { totalCount, data: hospitals } = this.getPagedData();
    return (
      <div>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          <h2 style={{ fontFamily: "cursive", textAlign: "center" }}>
            Hospitals list
          </h2>
          <div className="m-4 d-flex justify-content-end">
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
          </div>
          {/* <Button on> + Add hospital</Button> */}
          <Link className="mx-4" to="/admin/hospitals/new">
            new hospitals
          </Link>
          <HospitalTable data={hospitals} />
          <div className="m-2">
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Hospital;
