import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';


export default function PrepareDialog({open, handleClose, order, onSuccess}) {
  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Pick up Call form"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {order.cust_first_name} {order.cust_last_name}
            <br />
            <Button variant='outlined'>
            {order.price} birr
            </Button>
            <hr />
            <a href={`tel:${order.cust_phone_number}`}>{order.cust_phone_number}</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose(order._id,`${moment(Date.now()).format('YYYY-MM-DD hh:mm')}--customer did not answer `)}>Patient not answering</Button>
          <Button onClick={()=>handleClose(order._id,`${moment(Date.now()).format('YYYY-MM-DD hh:mm')}--customer is coming `)}>Patient is coming</Button>
          <Button onClick={()=> onSuccess(order._id)}>Picked up</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
