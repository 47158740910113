import { Button} from '@mui/material';
import React, { Component } from 'react';
import { addComponent, changeStatus,  getComponents } from '../../services/components';
import Table from '../common/table';
import ComponentPopup from './componentPopUp';
import NavBarConfigurer from './nav_bar_configurred';
import CircularProgressIndictor from '../common/circular_progress';
import { getInquiries } from '../../services/inquire';
import moment from 'moment';

class SeeInquiries extends Component {
    state = { 
        inquiries: [],
        status:-1
     }
    async componentDidMount(){
        this.setState({status:0})
       let inquiries=await getInquiries()
       this.setState({inquiries,status:-1})
    }
    columns=[
        {
            path: 'user.first_name',
            label:'First name',
        },
        {
            path: 'user.last_name',
            label:'Last name'
        },
        {
            path: 'email',
            label: 'Email',
            content : (inquiry)=> <a style={{textDecoration: 'none', color:"orange"}} href={`mailto:${inquiry.email}`}>{inquiry.email}</a>
        },
        {
            path :'phoneNumber',
            label :'Phone number',
            content : (inquiry)=> <a style={{textDecoration: 'none', color:"orange"}} href={`tel:${inquiry.phoneNumber}`}>{inquiry.phoneNumber}</a>
        },
        {
            path :'message',
            label :'Comment',
            content: (inquiry)=><details><summary>Message</summary><p>{inquiry.message}</p></details>
        },
        {
           path: 'createdAt',
           label: "Created at",
           content: (inquiry) => <React.Fragment>{moment(inquiry.createdAt).format('YYYY-MM-DD hh:mm')}</React.Fragment>
        }
    ]
    render() { 
        return (
            <React.Fragment>
                <div style={{display: this.state.status==0? 'block' : 'none'}}>
                    <CircularProgressIndictor />
                </div>
                <div style={{opacity: this.state.status==0? 0.7 : 1}}>
                    <NavBarConfigurer />
                    <h3>Inquiries</h3>
                    {this.state.inquiries && <Table columns={this.columns} data={this.state.inquiries} />}
                </div>
            </React.Fragment>
        );
    }
}
 
export default SeeInquiries;