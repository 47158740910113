import React, { Component } from "react";
import { getBranchData, getData } from "../../../services/graphRelated";
import { PieChart, Pie, Tooltip, Legend } from "recharts";
import CircularProgressIndictor from "../../common/circular_progress";
class PieGraph extends Component {
  state = {
    data: [],
    status: -1,
  };

  async componentDidMount() {
    this.setState({ status: 0 });
    let data = await getBranchData(this.props.year);
    this.setState({ data, status: -1 });
  }

  async componentDidUpdate(prevProps, _) {
    if (prevProps.year != this.props.year) {
      this.setState({ status: 0 });
      let data = await getBranchData(this.props.year);
      this.setState({ data, status: -1 });
    }
  }
  render() {
    return (
      <div className="d-flex justify-content-center">
        <div className="card" style={{ width: "250px" }}>
          <h4 style={{ fontFamily: "cursive" }}>Branch- Order pie chart</h4>
          <PieChart width={250} height={250} data={this.state.data}>
            <Tooltip />
            <Pie
              data={this.state.data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={50}
              fill="#8884d8"
            />
          </PieChart>
        </div>{" "}
      </div>
    );
  }
}

export default PieGraph;
