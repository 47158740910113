import http from "./httpService";
import config from '../config.json'

export async function getCategories(){
    let result = await http.get(config.apiUrl + '/categories')
    return result.data
}

export async function getComponents(id){
    let result = await http.get(config.apiUrl + `/components/categories/${id}`)
    return result.data
}
export async function changeStatus(id){
    let result = await http.delete(config.apiUrl + `/components/${id}`)
    return result.status
}
export async function addComponent(name, category){
    let result = await http.post(config.apiUrl + `/components`, {name, category})
    return result.status
}
export async function addCategory(name){
    let result = await http.post(config.apiUrl + `/categories`, {name})
    return result.status
}