import React from "react";
const InputField = ({ name, label,error, ...rest}) => {
  return (
    // <div className="form-group">
    //   <label htmlFor={name}>{label}</label>
    //   <input {...rest} name={name} id={name} className="form-control" />
    //   {error && <div className="alert alert-danger"> {error}</div>}
    // </div>
    <div className='my-2'>
        <label className='label' style={{fontWeight: 300, fontSize:20}} for={name}>{label}</label>
        <input style={{height: '35px', width: '200px',borderWidth:'0.5px', borderBottomColor: 'orange'}} name={name}  {...rest}/>
        {error && <p style={{color: 'red'}}>{error}</p>}
    </div>
  );
};

export default InputField;

