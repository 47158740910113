import React from "react";
import Form from "./common/form";
import Joi from "joi-browser";
import "../App.css";
import {
  getHospitals,
  getSpecializations,
  registerDoctor,
} from "../services/registerService";
import NavBar from "./common/navbar";
import { Autocomplete, Button, TextField } from "@mui/material";
import CircularProgressIndictor from "./common/circular_progress";
class SignUp extends Form {
  state = {
    workplaces: [1],
    data: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      gender: "male",
      proffessionId: "",
      doc_work: {},
      password: "",
    },
    genders: [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
    ],
    proffessions: [],
    hospitals: [],
    errors: {},
    status: -1,
  };
  schema = {
    first_name: Joi.string().min(3).max(20).label("First name").required(),
    last_name: Joi.string().min(3).max(20).label("Last name").required(),
    email: Joi.string().email().min(3).max(30).required(),
    phone: Joi.string()
      .min(10)
      .max(10)
      .required()
      .regex(new RegExp("^[0-9]+$")),
    gender: Joi.string().required(),
    proffessionId: Joi.string().required(),
    doc_work: Joi.object().required(),
    password: Joi.string().required(),
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let proffessions = await getSpecializations();
    this.setState({
      proffessions,
    });
    // let data ={...this.state.data}
    // data.proffessionId=proffessions[0].name
    // this.setState({data})
    let hospitals = await getHospitals();
    this.setState({ hospitals });
    // const {doc_work} = data
    // doc_work[1] =hospitals[0].name
    this.setState({ status: -1 });
  }
  links = [
    {
      label: (
        <Button variant="outlined" color="warning">
          Signin
        </Button>
      ),
      path: "/",
    },
    {
      label: (
        <Button variant="contained" color="warning">
          Signup
        </Button>
      ),
      path: "/signup",
    },
  ];
  doSubmit = async () => {
    let errors = { ...this.state.errors };
    if (this.state.data.proffessionId == 0) {
      errors.proffessionId = "Please choose your specialization";
      this.setState({ errors });
      return;
    }
    if (Object.keys(this.state.data.doc_work).length == 0) {
      errors.doc_work = " Please choose a hospital";
      this.setState({ errors });
      return;
    }
    for (let ell of Object.keys(this.state.data.doc_work)) {
      if (this.state.data.doc_work[ell].length <= 2) {
        errors.doc_work = "Please choose a correct hospital";
        this.setState({ errors });
        return;
      }
    }
    this.setState({ status: 0 });
    try {
      const status = await registerDoctor(this.state.data);
      if (status >= 200 && status < 400) {
        this.props.history.replace("/");
      }
    } catch (_) {
      let emailInUse = { ...this.state.errors };
      emailInUse.email = "Email already in use";
      this.setState({ errors: emailInUse });
    } finally {
      this.setState({ status: -1 });
    }
  };
  handleValue = (value) => {
    this.setState({ selectValue: value.target.value });
  };
  handleProffession = (val) => {
    let data = { ...this.state.data };
    data.proffessionId = val;
    this.setState({ data });
  };
  render() {
    return (
      <React.Fragment>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div
          style={{
            backgroundColor: "#efefef",
            position: "relative",
            opacity: this.state.status == 0 ? 0.7 : 1,
          }}
        >
          <NavBar links={this.links} />
          <div className="container">
            <div className="my-2">
              <div className="my-2">
                <h4 style={{ fontSize: "30px", textAlign: "center" }}>
                  <span style={{ color: "orange", fontWeight: "400" }}>
                    Sign
                  </span>{" "}
                  <span style={{ color: "orange", fontWeight: "200" }}>Up</span>
                </h4>
                {/* <hr style={{width: '70px', height: '3px' ,color: 'brown', textAlign: 'center', backgroundColor: 'brown', margin: '0 auto'}} /> */}
              </div>
              <div className="d-flex justify-content-center">
                <form
                  className="padded"
                  style={{ backgroundColor: "white", marginBottom: "30px" }}
                >
                  <div className="d-sm-flex">
                    <div>{this.renderInput2("first_name", "First name")}</div>
                    <div className="mx-3"></div>
                    <div>{this.renderInput2("last_name", "Last name")}</div>
                  </div>
                  <div className="d-sm-flex">
                    <div>{this.renderInput2("email", "Email", "email")}</div>
                    <div className="mx-3"></div>
                    <div>
                      {this.renderInput2("phone", "Phone number", "phone")}
                    </div>
                  </div>
                  <div className="width_setter">
                    {this.renderInput2("password", "Password", "password")}
                  </div>
                  <div className="d-sm-flex align-items-center">
                    {this.renderRadio("gender", "Gender", this.state.genders)}
                    <div className="mx-5"></div>
                    <div style={{ width: "250px", marginTop: "10px" }}>
                      <Autocomplete
                        id="free-solo-demo"
                        onChange={(e, newVal) => this.handleProffession(newVal)}
                        value={this.state.data.proffessionId}
                        options={this.state.proffessions.map(
                          (option) => option.name
                        )}
                        renderInput={(params) => {
                          return (
                            <TextField {...params} label="Select proffession" />
                          );
                        }}
                      />
                      {this.state.errors.proffessionId && (
                        <div style={{ color: "red" }}>
                          Please choose a Proffession
                        </div>
                      )}
                    </div>
                  </div>
                  {this.showWorkplace()}
                  <div className="my-4">
                    <Button
                      type="button"
                      variant="outlined"
                      color="warning"
                      className="my-1"
                      onClick={this.handleAddWorkPlace}
                    >
                      + Add Workplace
                    </Button>
                  </div>
                  <div className="my-2">{this.renderButton("Submit")}</div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  doctorSpecificForm = () => {
    return <React.Fragment></React.Fragment>;
  };
  handleAddWorkPlace = () => {
    let data = { ...this.state.data };
    let addedhospital =
      this.state.workplaces[this.state.workplaces.length - 1] + 1;
    let workplace = [...this.state.workplaces, addedhospital];
    data.doc_work[addedhospital] = "";
    this.setState({
      workplaces: workplace,
    });
    this.setState({ data });
  };
  removeClick = (workplace) => {
    let data = { ...this.state.data };
    delete data.doc_work[workplace];
    if (this.state.workplaces.length > 1) {
      let col = this.state.workplaces.filter((w) => w !== workplace);
      this.setState({
        workplaces: col,
      });
      this.setState({ data });
    }
  };

  showWorkplace = () => {
    return (
      <div className="width_setter">
        {this.renderMultipleSelect(
          this.state.workplaces,
          "doc_work",
          "Select hospital",
          this.state.hospitals
        )}
      </div>
    );
  };
}

export default SignUp;
