import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService";
const PharmacistRoute = ({ path, component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        let curr_user= auth.getCurrentUser()
        if (!curr_user || curr_user.role!='pharmacist')
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default PharmacistRoute;