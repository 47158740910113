import http from "./httpService";
import config from "../config.json";
import { saveAs } from "file-saver";

export async function getPendingOrders() {
  let result = await http.get(config.apiUrl + "/prescriptions/pending");
  return result.data;
}

export async function getOrder(id) {
  let result = await http.get(config.apiUrl + `/prescriptions/${id}`);
  return result.data;
}

///adding pharmacist info
export async function confirm(id, data) {
  let body = {};
  body.price = parseInt(data.price);
  body.type = data.type;
  if (data.area && data.area.length > 0) body.area = data.area;
  if (data.sub_area && data.sub_area.length > 0) body.sub_area = data.sub_area;
  // if (data.type=='pickup')
  //     body.branch = data.branch
  let result = await http.put(
    config.apiUrl + `/prescriptions/${id}/confirm`,
    body
  );
  return result.status;
}
//adding pharmacist information
export async function terminate(id, message) {
  let result = await http.put(
    config.apiUrl + `/prescriptions/${id}/terminated`,
    { message }
  );
  return result.status;
}

export async function getConfirmed() {
  let result = await http.get(config.apiUrl + `/prescriptions/confirmed`);
  return result.data;
}
export async function addToHistory(id, history) {
  let result = await http.put(config.apiUrl + `/prescriptions/${id}/history`, {
    history,
  });
  return result.status;
}
export async function pickedup(id) {
  let result = await http.delete(
    config.apiUrl + `/prescriptions/${id}/pickedup`
  );
  return result.status;
}

export async function sendToDel(id) {
  let result = await http.delete(
    config.apiUrl + `/prescriptions/${id}/prepared`
  );
  return result.status;
}
export async function getPrepared() {
  let result = await http.get(config.apiUrl + `/prescriptions/prepared`);
  return result.data;
}

export async function handleDownload(url, filename) {
  saveAs(url, filename);
}
// transferBranch(this.props.id, branch)
// /:id/transfer/:branch

export async function transferBranch(id, branch) {
  let result = await http.delete(
    config.apiUrl + `/prescriptions/${id}/transfer/${branch}`
  );
  return result.data;
}

export async function getTerminated() {
  let result = await http.get(config.apiUrl + `/prescriptions/terminated`);
  return result.data;
}

export async function getPickedUp() {
  let result = await http.get(config.apiUrl + `/prescriptions/pickedUp`);
  return result.data;
}
export async function getDelivery() {
  let result = await http.get(config.apiUrl + `/prescriptions/delivered`);
  return result.data;
}
