import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Radio from "./radios";
import Select from "./select";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/Remove";
import { Autocomplete, TextField } from "@mui/material";
import { uploadImage } from "../../services/imageService";
import InputField from "./input_filed";
import InputField2 from "./input_filed2";
import NewTextArea from "./new_text_area";
class Form extends Component {
  state = {
    data: {},
    errors: {},
    open: false,
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };
  handleChangeCustom = (value, tempo) => {
    const data = { ...this.state.data };
    data["doc_work"][tempo] = value;
    this.setState({ data });
  };
  onFileChange = async (event) => {
    this.setState({ status: 0 });
    let data = { ...this.state.data };
    data.image = event.target.files[0];
    this.setState({ data });
    let image_link = await uploadImage(data.image);
    this.setState({ image_link });
    this.setState({ status: -1 });
  };
  renderButton(label) {
    return (
      <Button variant="contained" color="warning" onClick={this.handleSubmit}>
        {label}
      </Button>
      // <button  className="btn btn-primary" onClick={this.handleSubmit}>
      //   {label}
      // </button>
    );
  }
  // // renderPrescriptionButton(label) {
  // //   return (
  // //     <button className="btn btn-primary" onClick={this.handleSubmit2}>
  // //       {label}
  // //     </button>
  // //   );
  // };

  renderInput(name, label, type = "text") {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }
  renderInput2(name, label, type = "text") {
    const { data, errors } = this.state;

    return (
      <InputField
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }
  renderInput3(name, label, type = "text") {
    const { data, errors } = this.state;

    return (
      <InputField2
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }
  renderTextArea(name, label, type = "text") {
    const { data, errors } = this.state;

    return (
      <NewTextArea
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }
  renderRadio(name, legend, options) {
    const { data } = this.state;

    return (
      <Radio
        name={name}
        value={data[name]}
        legend={legend}
        onChange={this.handleChange}
        options={options}
      />
    );
  }
  renderSelect(name, label, options) {
    const { data, errors } = this.state;
    return (
      <Select
        errors={errors[name]}
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
      />
    );
  }
  removeClick = (workplace) => {
    if (this.state.workplaces.length > 1) {
      let data = { ...this.state.data };
      delete data.doc_work[workplace];
      let col = this.state.workplaces.filter((w) => w !== workplace);
      this.setState({
        workplaces: col,
      });
      this.setState({ data });
    }
  };
  renderMultipleSelect(llist, name_in_data, label, options) {
    const { data, errors } = this.state;
    const res = data[name_in_data];
    return (
      <div>
        Select the hospitals you work in
        {llist.map((tempo) => (
          <div className="my-2">
            {/* <MultipleSelect 
                name={tempo}
                value={res[tempo]}
                label={label}
                options={options}
                onChange={this.handleChangeCustom}
                onRemove={this.removeClick}
                length={this.state.workplaces.length}
            /> */}
            <div className="d-flex align-items-center">
              <div style={{ width: "200px" }}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  value={res[tempo]}
                  onInputChange={(event, newValue) =>
                    this.handleChangeCustom(newValue, tempo)
                  }
                  options={options.map((option) => option.name)}
                  renderInput={(params) => {
                    return <TextField label="Hospital name" {...params} />;
                  }}
                />
              </div>
              {tempo != 1 && (
                <Button onClick={() => this.removeClick(tempo)}>
                  <RemoveIcon />
                </Button>
              )}
            </div>
          </div>
        ))}
        {this.state.errors.doc_work && (
          <div style={{ color: "red" }}>Please choose a hospital</div>
        )}
      </div>
    );
  }
}

export default Form;
