import React, { Component } from 'react';
import { getOrder } from '../../services/pharmacies';
import './img.css'
import NavBarConfigurer from './nav_bar_configurer';
import PrescriptionInfo from './prescription_info';
import CircularProgressIndictor from '../common/circular_progress';

class OrderChange extends Component {
    state = { 
        order: {},
        status:-1
     } 
    async componentDidMount(){
        this.setState({status:0})
        let order = await getOrder(this.props.match.params.id)
        this.setState({order, status:-1})

    }
    handleChange=()=>{
        return this.props.history.push('/pharmacist/home')
    }
    render() { 
        
        return (
            <div>
                <div style={{display: this.state.status==0? 'block' : 'none'}}>
                    <CircularProgressIndictor />
                </div>
                <div style={{opacity: this.state.status==0? 0.7 : 1}}>
                    <NavBarConfigurer />
                    {this.state.order.doctor &&<PrescriptionInfo order={this.state.order} id={this.props.match.params.id} onSuccess={this.handleChange}/>}
                </div>
            </div>
        );
    }
}
 
export default OrderChange;