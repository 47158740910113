import React, { Component } from 'react';
import Table from '../common/table';
import {Link} from 'react-router-dom';

class HospitalTable extends Component {
    state = { 
        currentPage:1,
        pageSize: 4,
     }
    columns = [
        {
          path: "name",
          label: "Name",
        },
        { 
            path: "doctors", label: "Doctors",
            content: hospital =>hospital.doctors.length>0? <Link to={`/admin/doctors/hospitals/${hospital._id}`}>{hospital.doctors.length}</Link>: <React.Fragment>{hospital.doctors.length}</React.Fragment> 
        },
        { 
            path: "order_count", label: "Orders",
            content: hospital =>hospital.order_count>0? <Link to={`/admin/orders/hospitals/${hospital._id}`}>{hospital.order_count}</Link>: <React.Fragment>{hospital.order_count}</React.Fragment>
        },
        
      ]; 
    render() { 
        let {data} =this.props

        return (
            <Table columns={this.columns} data={data} />
        );
    }
}
 
export default HospitalTable;