import  Joi  from 'joi-browser';
import React from 'react';
import { addPromotion } from '../../services/promotionServices';
import Form from '../common/form';
import NavBarConfigurer from './nav_bar_configurred';
import '../../App.css'
import { IconButton } from '@mui/material';
import config from '../../config.json'
import { PhotoCamera } from '@mui/icons-material';
import CircularProgressIndictor from '../common/circular_progress';

class AddPromotions extends Form {
    state = { 
        data:{
            title: "",
            description: "",
            image: null
        },
        image_link: null,
        errors:{},
        status:-1
     } 
     schema={
        title: Joi.string().required(),
        description: Joi.string().required(),
        image: Joi.any()
     }
     async doSubmit(){
        this.setState({status:0})
        await addPromotion(this.state.data,this.state.image_link)
        this.setState({status:-1})
        this.props.history.replace('/admin/promotions')
     }
     fileData = () => {
      if (this.state.data.image && this.state.image_link) {
        return (
          <div>
            <h2>File Details:</h2>
            <img style={{maxWidth: '700px', width: '80%'}} src={config.image + this.state.image_link} />
              <p>File Type: {this.state.data.image.type}</p>               
          </div>
        );
      } else {
        return (
          <div>
            <br />
            <em style={{color: 'red', }}>Promotion not added</em>
          </div>
        );
      }
    };
    render() { 
        return (
            <div>
              <div style={{display: this.state.status==0? 'block' : 'none'}}>
                    <CircularProgressIndictor />
                </div>
                <div style={{opacity: this.state.status==0? 0.7 : 1}}>
                <NavBarConfigurer />
                <div className="container">
                    <h3>Add promotion</h3>
                    <div className='width_setter'>
                        {this.renderInput('title', 'Title')}
                    </div>
                    <div>
                        {this.renderInput('description', 'Description')}
                    </div>
                    <h3>Promotion image</h3>
                    <IconButton color="primary" aria-label="upload picture" component="label">
                      <input hidden accept="image/*" type="file"  name='image' onChange={this.onFileChange}/>
                      <PhotoCamera />
                    </IconButton>
                    {this.fileData()}
                    {this.renderButton("Submit")}
                    </div>
                </div>
            </div>
            
        );
    }
}
 
export default AddPromotions;