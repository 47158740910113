import React, { Component } from "react";
import {
  deliverForm,
  getDriverHistory,
  getPreparedForDriver,
  getRecievedForDriver,
  recieveForm,
} from "../../services/driverService";
import NavBarConfigurer from "./nav_bar_configurer";
import Button from "@mui/material/Button";
import { Card, Grid } from "@mui/material";
import moment from "moment";
import CircularProgressIndictor from "../common/circular_progress";
import Table from "../common/table";

class DriverHome extends Component {
  state = {
    toBeRecieved: [],
    recieved: [],
    driverHistory: [],
    status: -1,
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let recieved = await getRecievedForDriver();
    this.setState({ recieved });
    let driverHistory = await getDriverHistory();
    this.setState({ driverHistory });
    let toBeRecieved = await getPreparedForDriver();
    this.setState({ toBeRecieved, status: -1 });
  }
  handleRecieved = async (id) => {
    this.setState({ status: 0 });
    let curr = this.state.toBeRecieved.find((o) => o._id == id);
    let toBeRecieved = this.state.toBeRecieved.filter((o) => o._id !== id);
    this.setState({ toBeRecieved });
    let recieved = [...this.state.recieved];
    recieved.push(curr);
    this.setState({ recieved });
    this.setState({ status: -1 });
    await recieveForm(id);
  };
  handleDelivered = async (id) => {
    this.setState({ status: 0 });
    let recieved = this.state.recieved.filter((o) => o._id !== id);
    this.setState({ recieved });
    this.setState({ status: -1 });
    await deliverForm(id);
  };
  columns = [
    {
      path: "",
      label: "Area",
      content: (order) =>
        order.area ? (
          <React.Fragment>{order.curr_status}</React.Fragment>
        ) : (
          "No area specified"
        ),
    },
    {
      path: "",
      label: "Recieved date",
      content: (order) => (
        <React.Fragment>
          {moment(order.recieved_date).format("dddd, MMMM Do hh:mm")}
        </React.Fragment>
      ),
    },
    {
      path: "",
      label: "Delivered date",
      content: (order) =>
        order.delivered_date ? (
          <React.Fragment>
            {moment(order.delivered_date).format("Do hh:mm")}
          </React.Fragment>
        ) : (
          "Not yet delivered"
        ),
    },
    {
      path: "",
      label: "Time taken to deliver",
      content: (order) =>
        order.delivered_date ? (
          <React.Fragment>
            {moment(order.delivered_date).diff(order.recieved_date, "m")} mins
          </React.Fragment>
        ) : (
          <React.Fragment>
            {moment(Date.now()).diff(order.recieved_date, "m")} mins
          </React.Fragment>
        ),
    },
  ];
  render() {
    return (
      <React.Fragment>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          <Grid container>
            <Grid item xs={12} md={12} lg={12} xl={8}>
              <div>
                {this.state.recieved.length > 0 && (
                  <h2 style={{ textAlign: "center", fontFamily: "cursive" }}>
                    Orders to deliver
                  </h2>
                )}
                <Grid container>
                  {this.state.recieved.map((order) => {
                    return (
                      <Grid item xs={12} md={6} lg={4}>
                        <Card className="m-2">
                          <ul>
                            <li>
                              {" "}
                              Customer name:{" "}
                              <span style={{ fontWeight: 600 }}>
                                {order.cust_first_name} {order.cust_last_name}
                              </span>{" "}
                            </li>
                            <li>
                              {" "}
                              Customer phone:{" "}
                              <a
                                href={`tel:${order.cust_phone_number}`}
                                style={{
                                  fontWeight: 600,
                                  textDecoration: "none",
                                }}
                              >
                                {order.cust_phone_number}
                              </a>
                            </li>
                            <li>
                              Order price:
                              <span style={{ fontWeight: 600 }}>
                                {order.price}birr{" "}
                              </span>
                            </li>
                            <li>
                              Cust. Area:
                              <span style={{ fontWeight: 600 }}>
                                {order.area}
                              </span>
                            </li>
                            <li>
                              Time taken:
                              <span style={{ fontWeight: 600 }}>
                                {moment(Date.now()).diff(
                                  order.recieved_date,
                                  "minutes"
                                )}{" "}
                                minutes
                              </span>{" "}
                            </li>
                          </ul>
                          <Button
                            onClick={() => this.handleDelivered(order._id)}
                          >
                            Delivered
                          </Button>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
              <div>
                {this.state.toBeRecieved.length > 0 && (
                  <h3 style={{ textAlign: "center", fontFamily: "cursive" }}>
                    Orders to recieve
                  </h3>
                )}
                <Grid container>
                  {this.state.toBeRecieved.map((order) => {
                    return (
                      <Grid item xs={12} md={6} lg={4}>
                        <Card>
                          <ul>
                            <li>
                              {" "}
                              Customer name:{" "}
                              <span style={{ fontWeight: 600 }}>
                                {order.cust_first_name} {order.cust_last_name}
                              </span>{" "}
                            </li>
                            <li>
                              {" "}
                              Customer phone:{" "}
                              <a
                                href={`tel:${order.cust_phone_number}`}
                                style={{
                                  fontWeight: 600,
                                  textDecoration: "none",
                                }}
                              >
                                {order.cust_phone_number}
                              </a>
                            </li>
                            <li>
                              Order price:
                              <span style={{ fontWeight: 600 }}>
                                {order.price}birr{" "}
                              </span>
                            </li>
                            <li>
                              Cust. Area:
                              <span style={{ fontWeight: 600 }}>
                                {order.area}
                              </span>
                            </li>
                          </ul>
                          <Button
                            onClick={() => this.handleRecieved(order._id)}
                          >
                            Recieved
                          </Button>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={4}>
              {this.state.driverHistory && (
                <div className="m-2">
                  <h2 style={{ fontFamily: "cursive", textAlign: "center" }}>
                    Your history
                  </h2>
                  <Table
                    columns={this.columns}
                    data={this.state.driverHistory}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default DriverHome;
