import http from "./httpService";
import config from '../config.json'


export async function uploadImage(image){
    const fd= new FormData();
    fd.append('image', image , image.name)
    let result = await http.post(config.apiUrl+'/image', fd)
    return result.data
}

export async function getDoctorsOfHospital(id){
    let result = await http.get(config.apiUrl + '/doctors/hospitals/' + id)
    return result.data
}