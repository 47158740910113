import http from "./httpService";
import config from "../config.json";
import _ from "lodash";

export async function getHospitals() {
  let result = await http.get(config.apiUrl + "/hospitals");
  return result.data;
}
export async function getSpecializations() {
  let result = await http.get(config.apiUrl + "/specializations");
  return result.data;
}
export async function registerDoctor(data) {
  let body = _.pick(data, ["first_name", "last_name", "email", "password"]);
  body.phoneNumber = data.phone;
  body.specialization = data.proffessionId;
  body.hospitals = Object.values(data.doc_work);
  body.gender = data.gender;
  let result = await http.post(config.apiUrl + "/doctors", body);
  return result.status;
}
