import React, { Component } from "react";
import Table from "../common/table";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { Button } from "@mui/material";
class OrderTable extends Component {
  columns = [
    {
      path: "custId",
      label: "Number",
    },
    {
      path: "",
      label: "patient name",
      content: (order) => (
        <React.Fragment>
          {order.cust_first_name} {order.cust_last_name}
        </React.Fragment>
      ),
    },
    {
      path: "",
      label: "doctor",
      content: (order) => (
        <React.Fragment>
          {order.doctor ? order.doctor.user.first_name : "WALKING CUSTOMER"}
        </React.Fragment>
      ),
    },
    {
      path: "curr_status",
      label: "Status",
    },
    {
      path: "",
      label: "Prescibed dt.",
      content: (order) => (
        <React.Fragment>
          {moment(order.prescribed_date).format("dddd, MMMM Do,YYYY hh:mm")}
        </React.Fragment>
      ),
    },
    {
      path: "",
      label: "Time taken ",
      content: (order) =>
        order.delivered_date ? (
          <React.Fragment>
            {moment(order.delivered_date).diff(order.prescribed_date, "h")}{" "}
            Hours
          </React.Fragment>
        ) : (
          <React.Fragment>Not yet</React.Fragment>
        ),
    },
    {
      path: "",
      label: "",
      content: (order) => (
        <Button variant="outlined" color="warning">
          {" "}
          <Link
            style={{ textDecoration: "none" }}
            to={`/admin/orders/${order._id}`}
          >
            Monitor
          </Link>
        </Button>
      ),
    },
  ];
  render() {
    let { data } = this.props;

    return <Table columns={this.columns} data={data} />;
  }
}

export default OrderTable;
