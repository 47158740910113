import { Button, Divider, List, ListItem } from '@mui/material';
import React, { Component } from 'react';
import { addBranch } from '../../services/addBranch';
import { getBranches } from '../../services/prescription';
import BranchPopUp from './branchesPopup';
import NavBarConfigurer from './nav_bar_configurred';
import CircularProgressIndictor from '../common/circular_progress';

class Branches extends Component {
    state = { 
        branches: null,
        open: false,
        status:-1
     }
     handleOpen=()=>{
        this.setState({open: true})
      }
      handleClose=()=>{
        this.setState({open: false})
      }
    async componentDidMount(){
        this.setState({status:0})
       let branches=await getBranches()
       this.setState({branches,status:-1})
    }
    handleSave= async(name)=>{
       this.handleClose()
       this.setState({status:0})
        await addBranch(name)
        let branches=await getBranches()
       this.setState({branches, status:-1})
    }
    render() { 
        return (
            <React.Fragment>
                <div style={{display: this.state.status==0? 'block' : 'none'}}>
                    <CircularProgressIndictor />
                </div>
                <div style={{opacity: this.state.status==0? 0.7 : 1}}>
                <NavBarConfigurer />
                <h3>Branches</h3>
                <Button onClick={this.handleOpen}>Add branch </Button>
                <div className="d-flex justify-content-center">
                    <List style={{width: '500px'}}>
                        {this.state.branches && this.state.branches.map(c=> <React.Fragment><ListItem style={{backgroundColor: '#daceee'}} className="d-flex justify-content-center">{c.name}</ListItem><Divider /></React.Fragment>)}
                        {this.state.open && <BranchPopUp open={this.state.open} handleClose={this.handleClose} onSave={this.handleSave} /> }
                    </List>
                </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Branches;