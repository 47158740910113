import React, { Component } from "react";
import Table from "../common/table";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Edit } from "@mui/icons-material";
class PendingTable extends Component {
  state = {};
  columns = [
    {
      path: "custId",
      label: "Order number",
    },
    {
      path: "cust_first_name",
      label: "Pat. Full-N.",
      content: (o) => (
        <React.Fragment>
          {o.cust_first_name} {o.cust_last_name}
        </React.Fragment>
      ),
    },
    {
      path: "cust_phone_number",
      label: "Phone number",
    },
    {
      path: "branch",
      label: "Branch",
      content: (order) =>
        order.branch ? (
          <React.Fragment>{order.branch.name}</React.Fragment>
        ) : (
          <React.Fragment>No branch</React.Fragment>
        ),
    },
    {
      path: "prescribed_date",
      label: "Prescribed date",
      content: (order) => (
        <React.Fragment>
          {moment(order.prescribed_date).format("dddd, MMMM Do, h:mm")}
        </React.Fragment>
      ),
    },
    {
      path: "doctor.user.first_name",
      label: "Doctor",
      content: (order) => (
        <React.Fragment>
          {order.doctor ? (
            <React.Fragment>{order.doctor.user.first_name}</React.Fragment>
          ) : (
            "WALKING CUSTOMER"
          )}
        </React.Fragment>
      ),
    },
    {
      path: "hospital.name",
      label: "Hospital",
    },
    {
      path: "",
      label: "Time taken",
      content: (order) => (
        <React.Fragment>
          {moment(Date.now()).diff(order.prescribed_date, "hours")} hours{" "}
          {moment(order.delivered_date).diff(order.prescribed_date, "minutes") %
            60}{" "}
          minutes
        </React.Fragment>
      ),
    },
    {
      path: "",
      label: "Edit",
      content: (order) => (
        <Link
          style={{ textDecoration: "none" }}
          to={`/pharmacists/pending/${order._id}`}
        >
          <Button color="warning" endIcon={<Edit />}>
            {" "}
            Confirm
          </Button>
        </Link>
      ),
    },
  ];
  render() {
    return <Table columns={this.columns} data={this.props.data} />;
  }
}

export default PendingTable;
