import React, { Component } from "react";
import NavBarConfigurer from "./nav_bar_configurer";
import "./doctor_dashboard.css";
import { getAllPromotions } from "../../services/promotionServices";
import config from "../../config.json";
import { Link } from "react-router-dom";
import Image1 from "./image_one.jpeg";
import Image2 from "./image_two.jpeg";
import Image3 from "./doctors.jpeg";
import CircularProgressIndictor from "../common/circular_progress";
class DocHome extends Component {
  state = {
    promotions: [],
    views: [],
    status: -1,
  };
  async componentDidMount() {
    this.setState({ status: 0 });
    let promotions = await getAllPromotions();
    this.setState({ promotions, status: -1 });
    let views = [];
    for (let i = 0; i < promotions.length; i++) {
      views.push(
        <div>
          <hr className="featurette-divider" />
          <div className="row featurette">
            <div className={i % 2 == 0 ? "col-md-7 order-md-2" : "col-md-7"}>
              <h2 className="featurette-heading fw-normal lh-1">
                {promotions[i].title}
              </h2>
              <p className="lead">{promotions[i].description}</p>
            </div>
            <div className={i % 2 == 0 ? "col-md-5 order-md-1" : "col-md-5"}>
              <img
                className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                width="400"
                height="500"
                src={config.image + promotions[i].image_link}
              />
            </div>
          </div>
        </div>
      );
    }
    this.setState({ views });
  }
  render() {
    return (
      <main style={{ marginBottom: "30px" }}>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <NavBarConfigurer />
          <div
            id="myCarousel"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div
                  style={{ backgroundColor: "black", opacity: 0.6 }}
                  className="d-flex justify-content-center"
                >
                  <img
                    className="bd-placeholder-img"
                    height="100%"
                    src={Image1}
                  />
                </div>
                <div className="container">
                  <div className="carousel-caption text-start">
                    <h1>Prescribe medicine</h1>
                    <p>
                      Order personalized medicines for your patients and we will
                      get it delivered on time.
                    </p>
                    <p>
                      <Link
                        className="btn btn-lg btn-warning"
                        to="/doctor/prescribe"
                      >
                        Order online
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div
                  style={{ backgroundColor: "black", opacity: 0.6 }}
                  className="d-flex justify-content-center"
                >
                  <img
                    className="bd-placeholder-img"
                    height="100%"
                    src={Image2}
                  />
                </div>
                <div className="container">
                  <div className="carousel-caption">
                    <h1>Track down your orders</h1>
                    <p>You can track down your past orders</p>
                    <p>
                      <Link
                        className="btn btn-lg btn-warning"
                        to="/doctor/orders"
                      >
                        Track
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div
                  style={{ backgroundColor: "black", opacity: 0.6 }}
                  className="d-flex justify-content-center"
                >
                  <img
                    className="bd-placeholder-img"
                    height="100%"
                    src={Image3}
                  />
                </div>

                <div className="container">
                  <div className="carousel-caption text-end">
                    <h1>Contact our proffesionals</h1>
                    <p>
                      You can contact our pharmacists for more information about
                      our services.
                    </p>
                    <p>
                      <Link
                        to="/doctor/contact"
                        className="btn btn-lg btn-warning"
                        onClick={this.handleOpen}
                      >
                        Contact us
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#myCarousel"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
          <div className="container marketing">
            {console.log(this.state.views)}
            {this.state.views.map((view) => (
              <div>{view}</div>
            ))}
          </div>
        </div>
      </main>
    );
  }
}

export default DocHome;
