import React from 'react';
import './nav_bar.css'
import { NavLink ,Link} from 'react-router-dom';
import auth from '../../services/authService';
const NavBar = ({links}) => {
    return (  
        <main>
        <nav className="navbar navbar-expand-lg navbar-light colori" aria-label="Offcanvas navbar large">
          <div className="container-fluid">
            <NavLink className="navbar-brand" to='/'>International pharmacy</NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="offcanvas offcanvas-end text-bg-light" tabindex="-1" id="offcanvasNavbar2" aria-labelledby="offcanvasNavbar2Label">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbar2Label">International Pharmacy</h5>
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                  {links.map(each=>{
                      return <li className="nav-item">
                              {each.path ? <NavLink className="nav-link" aria-current="page"  to={each.path}>{each.label}</NavLink>
                         : <Link className="nav-link" onClick={()=> auth.logout()} aria-current="page"  to={'/'}>Logout</Link> 
                          }
                        </li>
                  })}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </main>
      
    );
}
 
export default NavBar;