import React, { Component } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class ResetPassword extends Component {
    state = { 
        password: null,
        error: null
    }
     handleChange=(e)=>{
        this.setState({password : e.target.value, error:null})
     }
     doSubmit=()=>{
        if(!this.state.password || this.state.password.length<5){
            this.setState({error: 'Password is week'})
            return
        }
        this.props.onSave(this.props.user._id, this.state.password)
     }
    render() { 
        let {open, handleClose, onSave, user} =this.props
        return (
            <div>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Change password</DialogTitle>
              <DialogContentText>
                User: {user.first_name} {user.last_name}
              </DialogContentText>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="password"
                  label="Passsword"
                  fullWidth
                  type='password'
                  variant="standard"
                  maxRows={3}
                  value= {this.state.password}
                  onChange={this.handleChange}
                />
                {this.state.error && <p style={{color: 'red'}}>{this.state.error}</p>}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={this.doSubmit}>Save</Button>
              </DialogActions>
            </Dialog>
          </div>
        );
    }
}
 
export default ResetPassword;
