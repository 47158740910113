import React,{Component} from 'react';
import { getHospitalsOrder } from '../../services/adminPrescription';
import NavBarConfigurer from './nav_bar_configurred';
import OrderTable from './order_table';
import CircularProgressIndictor from '../common/circular_progress';


class HospitalOrder extends Component {
    state = { 
        orders: [],
        status:-1
     } 
    async componentDidMount(){
        this.setState({status:0})
        let orders = await getHospitalsOrder(this.props.match.params.id)
        this.setState({orders, status:-1})
    }
    render() { 
        return (
            <div>
                 <div style={{display: this.state.status==0? 'block' : 'none'}}>
                    <CircularProgressIndictor />
                </div>
                <div style={{opacity: this.state.status==0? 0.7 : 1}}>
                    <NavBarConfigurer />
                    <h1>Order</h1>
                <OrderTable data={this.state.orders} />
              </div>
            </div>
        );
    }
}
 
export default HospitalOrder;