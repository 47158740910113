import React, { Component } from "react";
import { getData } from "../../../services/graphRelated";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import CircularProgressIndictor from "../../common/circular_progress";
class Bargraph extends Component {
  state = {
    data: [],
    status: -1,
  };

  async componentDidMount() {
    this.setState({ status: 0 });
    let data = await getData(this.props.year);
    this.setState({ data, status: -1 });
  }

  async componentDidUpdate(prevProps, _) {
    if (prevProps.year != this.props.year) {
      this.setState({ status: 0 });
      let data = await getData(this.props.year);
      this.setState({ data, status: -1 });
    }
  }
  render() {
    return (
      <div>
        <div style={{ display: this.state.status == 0 ? "block" : "none" }}>
          <CircularProgressIndictor />
        </div>
        <div style={{ opacity: this.state.status == 0 ? 0.7 : 1 }}>
          <div
            style={{ overflowX: "scroll", width: "1200px", height: "300px" }}
          >
            <BarChart width={1200} height={250} data={this.state.data}>
              <CartesianGrid strokeDasharray="5 5" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="terminated" fill="#ff0000" />
              <Bar dataKey="preparing" fill="#FFA500" />
              <Bar dataKey="delivered" fill="#000000" />
              <Bar dataKey="pickedup" fill="#191" />
            </BarChart>
          </div>
        </div>
      </div>
    );
  }
}

export default Bargraph;
