import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import './table.css'
const Table = ({ columns, data }) => {
  return (
    <div class="table-responsive" id="no-more-tables">
      <table className="table bg-white container my-5">
      {console.log(window.innerWidth)}
        <TableHeader columns={columns}  />
        <TableBody columns={columns} data={data} />
      </table>
    </div>
  );
};

export default Table;
